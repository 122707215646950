@font-face {
  font-family: Fira Code;
  src: url("FiraCode-Light.7b89631f.woff2") format("woff2"), url("FiraCode-Light.94b78575.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Fira Code;
  src: url("FiraCode-Regular.55bf0023.woff2") format("woff2"), url("FiraCode-Regular.b441fa7c.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Fira Code;
  src: url("FiraCode-Medium.9230613e.woff2") format("woff2"), url("FiraCode-Medium.422ca67b.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Fira Code;
  src: url("FiraCode-SemiBold.5de00062.woff2") format("woff2"), url("FiraCode-SemiBold.65c0aabf.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Fira Code;
  src: url("FiraCode-Bold.e2f086f3.woff2") format("woff2"), url("FiraCode-Bold.168a31de.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Fira Code VF;
  src: url("FiraCode-VF.ec09f35c.woff2") format("woff2-variations"), url("FiraCode-VF.01f9807e.woff") format("woff-variations");
  font-weight: 300 700;
  font-style: normal;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: Fira Code, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

:root, [data-theme] {
  background-color: hsla(var(--b1) / var(--tw-bg-opacity, 1) );
  color: hsla(var(--bc) / var(--tw-text-opacity, 1) );
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  --p: 326 100% 74%;
  --pf: 326 100% 59%;
  --sf: 265 89% 62%;
  --af: 31 100% 57%;
  --nf: 230 15% 24%;
  --b2: 231 15% 17%;
  --b3: 231 15% 15%;
  --pc: 326 100% 15%;
  --sc: 265 100% 16%;
  --ac: 31 100% 14%;
  --nc: 230 71% 86%;
  --inc: 191 100% 15%;
  --suc: 135 100% 13%;
  --wac: 65 100% 15%;
  --erc: 0 100% 93%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --s: 265 89% 78%;
  --a: 31 100% 71%;
  --n: 230 15% 30%;
  --b1: 231 15% 18%;
  --bc: 60 30% 96%;
  --in: 191 97% 77%;
  --su: 135 94% 65%;
  --wa: 65 92% 76%;
  --er: 0 100% 67%;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.alert {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
  border-radius: var(--rounded-box, 1rem);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.alert > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse) );
}

@media (min-width: 768px) {
  .alert {
    flex-direction: row;
  }

  .alert > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(0px * var(--tw-space-y-reverse) );
  }
}

.alert > :where(*) {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.artboard {
  width: 100%;
}

.avatar {
  display: inline-flex;
  position: relative;
}

.avatar > div {
  aspect-ratio: 1 / 1;
  display: block;
  overflow: hidden;
}

.avatar img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.avatar.placeholder > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.badge {
  height: 1.25rem;
  width: fit-content;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--n) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity) );
  border-radius: var(--rounded-badge, 1.9rem);
  justify-content: center;
  align-items: center;
  padding-left: .563rem;
  padding-right: .563rem;
  font-size: .875rem;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.btm-nav {
  width: 100%;
  height: 4rem;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
  color: currentColor;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.btm-nav > * {
  height: 100%;
  cursor: pointer;
  border-color: currentColor;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  gap: .25rem;
  display: flex;
  position: relative;
}

.breadcrumbs {
  max-width: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  overflow-x: auto;
}

.breadcrumbs > ul {
  white-space: nowrap;
  min-height: min-content;
  align-items: center;
  display: flex;
}

.breadcrumbs > ul > li {
  align-items: center;
  display: flex;
}

.breadcrumbs > ul > li > a {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.breadcrumbs > ul > li > a:hover {
  text-decoration-line: underline;
}

.btn {
  cursor: pointer;
  user-select: none;
  border-color: #0000;
  border-color: hsl(var(--n) / var(--tw-border-opacity) );
  text-align: center;
  border-radius: var(--rounded-btn, .5rem);
  height: 3rem;
  min-height: 3rem;
  text-transform: uppercase;
  text-transform: var(--btn-text-case, uppercase);
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity) );
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled] {
  pointer-events: none;
}

.btn-square {
  height: 3rem;
  width: 3rem;
  padding: 0;
}

.btn-circle {
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  padding: 0;
}

.btn.loading, .btn.loading:hover {
  pointer-events: none;
}

.btn.loading:before {
  height: 1rem;
  width: 1rem;
  content: "";
  border-width: 2px;
  border-color: #0000 currentColor currentColor #0000;
  border-radius: 9999px;
  margin-right: .5rem;
  animation: spin 2s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .btn.loading:before {
    animation: spin 10s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-group {
  flex-wrap: wrap;
  display: flex;
}

.btn-group > input[type="radio"].btn {
  appearance: none;
}

.btn-group > input[type="radio"].btn:before {
  content: attr(data-title);
}

.btn-group-vertical {
  flex-flow: column;
}

.card {
  border-radius: var(--rounded-box, 1rem);
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.card:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.card-body {
  padding: var(--padding-card, 2rem);
  flex-direction: column;
  flex: auto;
  gap: .5rem;
  display: flex;
}

.card-body :where(p) {
  flex-grow: 1;
}

.card-actions {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: .5rem;
  display: flex;
}

.card figure {
  justify-content: center;
  align-items: center;
  display: flex;
}

.card.image-full {
  display: grid;
}

.card.image-full:before {
  content: "";
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
  opacity: .75;
  border-radius: var(--rounded-box, 1rem);
  position: relative;
}

.card.image-full:before, .card.image-full > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.card.image-full > figure img {
  height: 100%;
  object-fit: cover;
}

.card.image-full > .card-body {
  z-index: 20;
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity) );
  position: relative;
}

.carousel {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  overflow-x: scroll;
}

.carousel-vertical {
  scroll-snap-type: y mandatory;
  flex-direction: column;
  overflow-y: scroll;
}

.carousel-item {
  box-sizing: content-box;
  scroll-snap-align: start;
  flex: none;
  display: flex;
}

.carousel-center .carousel-item {
  scroll-snap-align: center;
}

.carousel-end .carousel-item {
  scroll-snap-align: end;
}

.checkbox {
  --chkbg: var(--bc);
  --chkfg: var(--b1);
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-border-opacity: .2;
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 0;
}

.collapse {
  display: grid;
  position: relative;
  overflow: hidden;
}

.collapse-title, .collapse > input[type="checkbox"], .collapse-content {
  grid-row-start: 1;
  grid-column-start: 1;
}

.collapse > input[type="checkbox"] {
  appearance: none;
  opacity: 0;
}

.collapse-content {
  max-height: 0;
  cursor: unset;
  grid-row-start: 2;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: padding .2s ease-in-out, background-color .2s ease-in-out;
  overflow: hidden;
}

.collapse-open .collapse-content, .collapse:focus:not(.collapse-close) .collapse-content, .collapse:not(.collapse-close) input[type="checkbox"]:checked ~ .collapse-content {
  max-height: 9000px;
}

:root .countdown {
  line-height: 1em;
}

.countdown {
  display: inline-flex;
}

.countdown > * {
  height: 1em;
  display: inline-block;
  overflow-y: hidden;
}

.countdown > :before {
  content: "00\a 01\a 02\a 03\a 04\a 05\a 06\a 07\a 08\a 09\a 10\a 11\a 12\a 13\a 14\a 15\a 16\a 17\a 18\a 19\a 20\a 21\a 22\a 23\a 24\a 25\a 26\a 27\a 28\a 29\a 30\a 31\a 32\a 33\a 34\a 35\a 36\a 37\a 38\a 39\a 40\a 41\a 42\a 43\a 44\a 45\a 46\a 47\a 48\a 49\a 50\a 51\a 52\a 53\a 54\a 55\a 56\a 57\a 58\a 59\a 60\a 61\a 62\a 63\a 64\a 65\a 66\a 67\a 68\a 69\a 70\a 71\a 72\a 73\a 74\a 75\a 76\a 77\a 78\a 79\a 80\a 81\a 82\a 83\a 84\a 85\a 86\a 87\a 88\a 89\a 90\a 91\a 92\a 93\a 94\a 95\a 96\a 97\a 98\a 99\a ";
  white-space: pre;
  top: calc(var(--value) * -1em);
  text-align: center;
  transition: all 1s cubic-bezier(1, 0, 0, 1);
  position: relative;
}

.divider {
  height: 1rem;
  white-space: nowrap;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.divider:before, .divider:after {
  content: "";
  height: .125rem;
  width: 100%;
  flex-grow: 1;
}

.drawer {
  width: 100%;
  height: 100vh;
  height: 100dvh;
  display: grid;
  overflow: hidden;
}

.drawer.drawer-end {
  direction: rtl;
}

.drawer.drawer-end > * {
  direction: ltr;
}

.drawer.drawer-end > .drawer-toggle ~ .drawer-side > .drawer-overlay + * {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  justify-self: end;
}

.drawer.drawer-end > .drawer-toggle:checked ~ .drawer-side > .drawer-overlay + * {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

:where(.drawer-toggle ~ .drawer-content) {
  height: inherit;
}

.drawer-toggle {
  height: 0;
  width: 0;
  appearance: none;
  opacity: 0;
  position: absolute;
}

.drawer-toggle ~ .drawer-content {
  z-index: 0;
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  overflow-y: auto;
}

.drawer-toggle ~ .drawer-side {
  max-height: 100vh;
  grid-row-start: 1;
  grid-column-start: 1;
  display: grid;
}

.drawer-toggle ~ .drawer-side > .drawer-overlay {
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity) );
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.drawer-toggle ~ .drawer-side > .drawer-overlay + * {
  z-index: 10;
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.drawer-toggle:checked ~ .drawer-side {
  overflow-y: auto;
}

.drawer-toggle:checked ~ .drawer-side > .drawer-overlay {
  visibility: visible;
  opacity: .999999;
  --tw-bg-opacity: .4;
}

.drawer-toggle:checked ~ .drawer-side > .drawer-overlay + * {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

[dir="rtl"] .drawer-toggle ~ .drawer-side > .drawer-overlay + * {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

[dir="rtl"] .drawer-toggle:checked ~ .drawer-side > .drawer-overlay + * {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@media (min-width: 1024px) {
  .drawer-mobile {
    grid-auto-columns: max-content auto;
  }

  .drawer-mobile > .drawer-toggle ~ .drawer-content {
    height: auto;
  }

  @media (min-width: 1024px) {
    .drawer-mobile > .drawer-toggle ~ .drawer-content {
      grid-column-start: 2;
    }

    .drawer-mobile > .drawer-toggle ~ .drawer-side > .drawer-overlay {
      visibility: visible;
    }

    .drawer-mobile > .drawer-toggle ~ .drawer-side > .drawer-overlay + * {
      --tw-translate-x: 0px;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
    }

    .drawer-mobile.drawer-end > .drawer-toggle ~ .drawer-content {
      grid-column-start: 1;
    }

    .drawer-mobile.drawer-end > .drawer-toggle ~ .drawer-side {
      grid-column-start: 2;
    }

    .drawer-mobile.drawer-end > .drawer-toggle ~ .drawer-side > .drawer-overlay {
      visibility: visible;
    }

    .drawer-mobile.drawer-end > .drawer-toggle ~ .drawer-side > .drawer-overlay + * {
      --tw-translate-x: 0px;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
    }
  }

  .drawer-mobile > .drawer-toggle ~ .drawer-side {
    overflow-y: auto;
  }

  .drawer-mobile.drawer-end {
    direction: ltr;
    grid-auto-columns: auto max-content;
  }

  .drawer-mobile.drawer-end > .drawer-toggle ~ .drawer-content {
    height: auto;
  }

  .drawer-mobile.drawer-end > .drawer-toggle ~ .drawer-side {
    overflow-y: auto;
  }

  .drawer-mobile > .drawer-toggle:checked ~ .drawer-content {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown > :focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.dropdown .dropdown-content {
  visibility: hidden;
  z-index: 50;
  opacity: 0;
  transform-origin: top;
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
}

.dropdown-end .dropdown-content {
  right: 0;
}

.dropdown-left .dropdown-content {
  transform-origin: 100%;
  top: 0;
  bottom: auto;
  right: 100%;
}

.dropdown-right .dropdown-content {
  transform-origin: 0;
  top: 0;
  bottom: auto;
  left: 100%;
}

.dropdown-top .dropdown-content {
  transform-origin: bottom;
  top: auto;
  bottom: 100%;
}

.dropdown-end.dropdown-right .dropdown-content, .dropdown-end.dropdown-left .dropdown-content {
  top: auto;
  bottom: 0;
}

.dropdown.dropdown-open .dropdown-content, .dropdown.dropdown-hover:hover .dropdown-content, .dropdown:not(.dropdown-hover):focus .dropdown-content, .dropdown:not(.dropdown-hover):focus-within .dropdown-content {
  visibility: visible;
  opacity: 1;
}

.footer {
  width: 100%;
  grid-auto-flow: row;
  place-items: start;
  gap: 2.5rem 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: grid;
}

.footer > * {
  place-items: start;
  gap: .5rem;
  display: grid;
}

.footer-center {
  text-align: center;
  place-items: center;
}

.footer-center > * {
  place-items: center;
}

@media (min-width: 48rem) {
  .footer {
    grid-auto-flow: column;
  }

  .footer-center {
    grid-auto-flow: row dense;
  }
}

.form-control {
  flex-direction: column;
  display: flex;
}

.label {
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.hero {
  width: 100%;
  background-position: center;
  background-size: cover;
  place-items: center;
  display: grid;
}

.hero > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.hero-overlay {
  height: 100%;
  width: 100%;
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
  --tw-bg-opacity: .5;
  grid-row-start: 1;
  grid-column-start: 1;
}

.hero-content {
  z-index: 0;
  max-width: 80rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.indicator {
  width: max-content;
  display: inline-flex;
  position: relative;
}

.indicator :where(.indicator-item) {
  z-index: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  position: absolute;
}

.input {
  height: 3rem;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  line-height: 2;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.input-group {
  width: 100%;
  align-items: stretch;
  display: flex;
}

.input-group > .input {
  isolation: isolate;
}

.input-group > *, .input-group > .input {
  border-radius: 0;
}

.input-group-md {
  font-size: .875rem;
  line-height: 2;
}

.input-group-lg {
  font-size: 1.125rem;
  line-height: 2;
}

.input-group-sm {
  font-size: .875rem;
  line-height: 2rem;
}

.input-group-xs {
  font-size: .75rem;
  line-height: 1.625;
}

.input-group :where(span) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) );
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.input-group :first-child {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: 0;
}

.input-group :last-child {
  border-top-left-radius: 0;
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.input-group-vertical {
  flex-direction: column;
}

.input-group-vertical :first-child {
  border-top-left-radius: var(--rounded-btn, .5rem);
  border-top-right-radius: var(--rounded-btn, .5rem);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-vertical :last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--rounded-btn, .5rem);
  border-bottom-right-radius: var(--rounded-btn, .5rem);
}

.kbd {
  border-width: 1px;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity) );
  --tw-border-opacity: .2;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
  border-radius: var(--rounded-btn, .5rem);
  min-height: 2.2em;
  min-width: 2.2em;
  border-bottom-width: 2px;
  justify-content: center;
  align-items: center;
  padding-left: .5rem;
  padding-right: .5rem;
  display: inline-flex;
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.link-hover {
  text-decoration-line: none;
}

.link-hover:hover {
  text-decoration-line: underline;
}

.mask {
  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;
}

.mask-half-1 {
  mask-position: 0;
  mask-size: 200%;
}

.mask-half-2 {
  mask-position: 100%;
  mask-size: 200%;
}

.menu {
  flex-direction: column;
  display: flex;
}

.menu.horizontal {
  flex-direction: row;
  display: inline-flex;
}

.menu.horizontal :where(li) {
  flex-direction: row;
}

.menu :where(li) {
  flex-flow: column wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.menu :where(li:not(.menu-title)) > :where(:not(ul)) {
  display: flex;
}

.menu :where(li:not(.disabled):not(.menu-title)) > :where(:not(ul)) {
  cursor: pointer;
  user-select: none;
  outline-offset: 2px;
  outline: 2px solid #0000;
  align-items: center;
}

.menu > :where(li > :not(ul):focus) {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.menu > :where(li.disabled > :not(ul):focus) {
  cursor: auto;
}

.menu > :where(li) :where(ul) {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.menu > :where(li) > :where(ul) {
  top: initial;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  display: none;
  position: absolute;
  left: 100%;
}

.menu > :where(li:hover) > :where(ul) {
  display: flex;
}

.menu > :where(li:focus) > :where(ul) {
  display: flex;
}

.mockup-code {
  min-width: 18rem;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity) );
  border-radius: var(--rounded-box, 1rem);
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.mockup-code pre[data-prefix]:before {
  content: attr(data-prefix);
  text-align: right;
  width: 2rem;
  opacity: .5;
  display: inline-block;
}

.mockup-window {
  border-radius: var(--rounded-box, 1rem);
  padding-top: 1.25rem;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.mockup-window pre[data-prefix]:before {
  content: attr(data-prefix);
  text-align: right;
  display: inline-block;
}

.modal {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity) );
  --tw-bg-opacity: .4;
  overscroll-behavior: contain;
  justify-content: center;
  transition-property: transform, opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: fixed;
  inset: 0;
  overflow-y: hidden;
}

:where(.modal) {
  align-items: center;
}

.modal-box {
  max-height: calc(100vh - 5em);
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
  width: 91.6667%;
  max-width: 32rem;
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  overscroll-behavior: contain;
  padding: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  overflow-y: auto;
  box-shadow: 0 25px 50px -12px #00000040;
}

.modal-open, .modal:target, .modal-toggle:checked + .modal {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.modal-action {
  justify-content: flex-end;
  margin-top: 1.5rem;
  display: flex;
}

.modal-toggle {
  height: 0;
  width: 0;
  appearance: none;
  opacity: 0;
  position: fixed;
}

.navbar {
  padding: var(--navbar-padding, .5rem);
  min-height: 4rem;
  width: 100%;
  align-items: center;
  display: flex;
}

:where(.navbar > *) {
  align-items: center;
  display: inline-flex;
}

.navbar-start {
  width: 50%;
  justify-content: flex-start;
}

.navbar-center {
  flex-shrink: 0;
}

.navbar-end {
  width: 50%;
  justify-content: flex-end;
}

.progress {
  width: 100%;
  appearance: none;
  height: .5rem;
  border-radius: var(--rounded-box, 1rem);
  position: relative;
  overflow: hidden;
}

.radial-progress {
  height: var(--size);
  width: var(--size);
  vertical-align: middle;
  box-sizing: content-box;
  --value: 0;
  --size: 5rem;
  --thickness: calc(var(--size) / 10);
  background-color: #0000;
  border-radius: 9999px;
  place-content: center;
  display: inline-grid;
  position: relative;
}

.radial-progress::-moz-progress-bar {
  appearance: none;
  background-color: #0000;
}

.radial-progress::-webkit-progress-value {
  appearance: none;
  background-color: #0000;
}

.radial-progress::-webkit-progress-bar {
  appearance: none;
  background-color: #0000;
}

.radial-progress:before, .radial-progress:after {
  content: "";
  border-radius: 9999px;
  position: absolute;
}

.radial-progress:before {
  background: radial-gradient(farthest-side, currentColor 98%, #0000) top / var(--thickness) var(--thickness) no-repeat, conic-gradient(currentColor calc(var(--value) * 1%), #0000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(99% - var(--thickness) ), #000 calc(100% - var(--thickness) ) );
  mask: radial-gradient(farthest-side, #0000 calc(99% - var(--thickness) ), #000 calc(100% - var(--thickness) ) );
  inset: 0;
}

.radial-progress:after {
  inset: calc(50% - var(--thickness) / 2);
  transform: rotate(calc(var(--value) * 3.6deg - 90deg) ) translate(calc(var(--size) / 2 - 50%) );
  background-color: currentColor;
}

.radio {
  --chkbg: var(--bc);
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-border-opacity: .2;
  transition: background, box-shadow var(--animation-input, .2s) ease-in-out;
  border-radius: 9999px;
  flex-shrink: 0;
}

.range {
  height: 1.5rem;
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  --range-shdw: var(--bc);
  border-radius: var(--rounded-box, 1rem);
  background-color: #0000;
  overflow: hidden;
}

.range:focus {
  outline: none;
}

.rating {
  display: inline-flex;
  position: relative;
}

.rating :where(input) {
  cursor: pointer;
  animation: rating-pop var(--animation-input, .25s) ease-out;
  height: 1.5rem;
  width: 1.5rem;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  --tw-bg-opacity: 1;
}

.select {
  cursor: pointer;
  user-select: none;
  appearance: none;
  height: 3rem;
  min-height: 3rem;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
  border-radius: var(--rounded-btn, .5rem);
  background-image: linear-gradient(45deg, #0000 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, #0000 50%);
  background-position: calc(100% - 20px) calc(1px + 50%), calc(100% - 16px) calc(1px + 50%);
  background-repeat: no-repeat;
  background-size: 4px 4px, 4px 4px;
  flex-shrink: 0;
  padding-left: 1rem;
  padding-right: 2.5rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 2;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.select[multiple] {
  height: auto;
}

.stack {
  place-items: flex-end center;
  display: inline-grid;
}

.stack > * {
  z-index: 1;
  width: 100%;
  opacity: .6;
  grid-row-start: 1;
  grid-column-start: 1;
  transform: translateY(1rem)scale(.9);
}

.stack > :nth-child(2) {
  z-index: 2;
  opacity: .8;
  transform: translateY(.5rem)scale(.95);
}

.stack > :nth-child(1) {
  z-index: 3;
  opacity: 1;
  transform: translateY(0)scale(1);
}

.stats {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  border-radius: var(--rounded-box, 1rem);
  display: inline-grid;
}

:where(.stats) {
  grid-auto-flow: column;
  overflow-x: auto;
}

.stat {
  width: 100%;
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-border-opacity: .1;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 1rem;
  padding: 1rem 1.5rem;
  display: inline-grid;
}

.stat-figure {
  grid-row: 1 / span 3;
  grid-column-start: 2;
  place-self: center end;
}

.stat-title {
  white-space: nowrap;
  opacity: .6;
  grid-column-start: 1;
}

.stat-value {
  white-space: nowrap;
  grid-column-start: 1;
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 2.5rem;
}

.stat-desc {
  white-space: nowrap;
  opacity: .6;
  grid-column-start: 1;
  font-size: .75rem;
  line-height: 1rem;
}

.stat-actions {
  white-space: nowrap;
  grid-column-start: 1;
  margin-top: 1rem;
}

.steps {
  counter-reset: step;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: inline-grid;
  overflow-x: auto;
  overflow-y: hidden;
}

.steps .step {
  text-align: center;
  min-width: 4rem;
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  place-items: center;
  display: grid;
}

.swap {
  user-select: none;
  cursor: pointer;
  place-content: center;
  display: inline-grid;
  position: relative;
}

.swap > * {
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: transform, opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.swap input {
  appearance: none;
}

.swap .swap-on, .swap .swap-indeterminate, .swap input:indeterminate ~ .swap-on, .swap input:checked ~ .swap-off, .swap.swap-active .swap-off, .swap input:indeterminate ~ .swap-off {
  opacity: 0;
}

.swap input:checked ~ .swap-on, .swap-active .swap-on, .swap input:indeterminate ~ .swap-indeterminate {
  opacity: 1;
}

.tabs {
  flex-wrap: wrap;
  align-items: flex-end;
  display: flex;
}

.tab {
  cursor: pointer;
  user-select: none;
  text-align: center;
  height: 2rem;
  --tab-padding: 1rem;
  --tw-text-opacity: .5;
  --tab-color: hsla(var(--bc) / var(--tw-text-opacity, 1) );
  --tab-bg: hsla(var(--b1) / var(--tw-bg-opacity, 1) );
  --tab-border-color: hsla(var(--b3) / var(--tw-bg-opacity, 1) );
  color: var(--tab-color);
  padding-left: var(--tab-padding, 1rem);
  padding-right: var(--tab-padding, 1rem);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: .875rem;
  line-height: 2;
  display: inline-flex;
  position: relative;
}

.table {
  text-align: left;
  position: relative;
}

.table th:first-child {
  z-index: 11;
  position: sticky;
  left: 0;
}

.textarea {
  min-height: 3rem;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 2;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.toast {
  min-width: fit-content;
  flex-direction: column;
  gap: .5rem;
  padding: 1rem;
  display: flex;
  position: fixed;
}

.toggle {
  --chkbg: hsla(var(--bc) / .2);
  --handleoffset: 1.5rem;
  height: 1.5rem;
  width: 3rem;
  cursor: pointer;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-border-opacity: .2;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  --tw-bg-opacity: .2;
  border-radius: var(--rounded-badge, 1.9rem);
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition: background, box-shadow var(--animation-input, .2s) ease-in-out;
  box-shadow: calc(var(--handleoffset) * -1) 0 0 2px hsl(var(--b1) ) inset, 0 0 0 2px hsl(var(--b1) ) inset;
  flex-shrink: 0;
}

.tooltip {
  --tooltip-offset: calc(100% + 1px + var(--tooltip-tail, 0px) );
  text-align: center;
  --tooltip-tail: 3px;
  --tooltip-color: hsl(var(--n) );
  --tooltip-text-color: hsl(var(--nc) );
  --tooltip-tail-offset: calc(100% + 1px - var(--tooltip-tail) );
  display: inline-block;
  position: relative;
}

.tooltip:before {
  pointer-events: none;
  content: attr(data-tip);
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-offset);
  max-width: 20rem;
  background-color: var(--tooltip-color);
  color: var(--tooltip-text-color);
  width: max-content;
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  position: absolute;
  transform: translateX(-50%);
}

.avatar-group {
  display: flex;
  overflow: hidden;
}

.avatar-group .avatar {
  --tw-border-opacity: 1;
  border-width: 4px;
  border-color: hsl(var(--b1) / var(--tw-border-opacity) );
  border-radius: 9999px;
  overflow: hidden;
}

.btn-outline .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity) );
}

.btn-outline.btn-primary .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.btn-outline.btn-secondary .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity) );
}

.btn-outline.btn-accent .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity) );
}

.btn-outline .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity) );
  background-color: #0000;
}

.btn-outline.btn-primary .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity) );
  background-color: #0000;
}

.btn-outline.btn-secondary .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity) );
  background-color: #0000;
}

.btn-outline.btn-accent .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity) );
  background-color: #0000;
}

.btn-outline.btn-info .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity) );
  background-color: #0000;
}

.btn-outline.btn-success .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity) );
  background-color: #0000;
}

.btn-outline.btn-warning .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity) );
  background-color: #0000;
}

.btn-outline.btn-error .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity) );
  background-color: #0000;
}

.btn-outline:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
}

.btn-outline:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity) );
}

.btn-outline.btn-primary:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pc) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pc) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity) );
}

.btn-outline.btn-primary:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pc) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.btn-outline.btn-secondary:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sc) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sc) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity) );
}

.btn-outline.btn-secondary:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sc) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf, var(--s)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity) );
}

.btn-outline.btn-accent:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--ac) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--ac) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity) );
}

.btn-outline.btn-accent:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--ac) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af, var(--a)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity) );
}

.btm-nav > :where(.active) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
  border-top-width: 2px;
}

.btm-nav > .disabled, .btm-nav > .disabled:hover, .btm-nav > [disabled], .btm-nav > [disabled]:hover {
  pointer-events: none;
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
  --tw-bg-opacity: .1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  --tw-text-opacity: .2;
}

.btm-nav > * .label {
  font-size: 1rem;
  line-height: 1.5rem;
}

.breadcrumbs > ul > li > a:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.breadcrumbs > ul > li > a:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.breadcrumbs > ul > li + :before {
  content: "";
  height: .375rem;
  width: .375rem;
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  opacity: .4;
  background-color: #0000;
  border-top: 1px solid;
  border-right: 1px solid;
  margin-left: .5rem;
  margin-right: .75rem;
  display: block;
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .95) );
  animation: none;
}

.btn:hover, .btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity) );
}

.btn:focus-visible {
  outline: 2px solid hsl(var(--nf) );
  outline-offset: 2px;
}

.btn-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.btn-primary:hover, .btn-primary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf, var(--p)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity) );
}

.btn-primary:focus-visible {
  outline: 2px solid hsl(var(--p) );
}

.btn-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity) );
}

.btn-secondary:hover, .btn-secondary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf, var(--s)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf, var(--s)) / var(--tw-bg-opacity) );
}

.btn-secondary:focus-visible {
  outline: 2px solid hsl(var(--s) );
}

.btn-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity) );
}

.btn-accent:hover, .btn-accent.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--af, var(--a)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af, var(--a)) / var(--tw-bg-opacity) );
}

.btn-accent:focus-visible {
  outline: 2px solid hsl(var(--a) );
}

.btn-info {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--inc, var(--nc)) / var(--tw-text-opacity) );
}

.btn-info:hover, .btn-info.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity) );
}

.btn-info:focus-visible {
  outline: 2px solid hsl(var(--in) );
}

.btn-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--suc, var(--nc)) / var(--tw-text-opacity) );
}

.btn-success:hover, .btn-success.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity) );
}

.btn-success:focus-visible {
  outline: 2px solid hsl(var(--su) );
}

.btn-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--wac, var(--nc)) / var(--tw-text-opacity) );
}

.btn-warning:hover, .btn-warning.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity) );
}

.btn-warning:focus-visible {
  outline: 2px solid hsl(var(--wa) );
}

.btn-error {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--erc, var(--nc)) / var(--tw-text-opacity) );
}

.btn-error:hover, .btn-error.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity) );
}

.btn-error:focus-visible {
  outline: 2px solid hsl(var(--er) );
}

.btn.glass:hover, .btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn.glass:focus-visible {
  outline: 2px solid 0 0 2px currentColor;
}

.btn-ghost {
  color: currentColor;
  background-color: #0000;
  border-width: 1px;
  border-color: #0000;
}

.btn-ghost:hover, .btn-ghost.btn-active {
  --tw-border-opacity: 0;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  --tw-bg-opacity: .2;
}

.btn-ghost:focus-visible {
  outline: 2px solid 0 0 2px currentColor;
}

.btn-link {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity) );
  background-color: #0000;
  border-color: #0000;
}

.btn-link:hover, .btn-link.btn-active {
  background-color: #0000;
  border-color: #0000;
  text-decoration-line: underline;
}

.btn-link:focus-visible {
  outline: 2px solid 0 0 2px currentColor;
}

.btn-outline {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  background-color: #0000;
  border-color: currentColor;
}

.btn-outline:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--b1) / var(--tw-text-opacity) );
}

.btn-outline.btn-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity) );
}

.btn-outline.btn-primary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf, var(--p)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.btn-outline.btn-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity) );
}

.btn-outline.btn-secondary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf, var(--s)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf, var(--s)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity) );
}

.btn-outline.btn-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity) );
}

.btn-outline.btn-accent:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--af, var(--a)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af, var(--a)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity) );
}

.btn-outline.btn-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity) );
}

.btn-outline.btn-success:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--suc, var(--nc)) / var(--tw-text-opacity) );
}

.btn-outline.btn-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity) );
}

.btn-outline.btn-info:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--inc, var(--nc)) / var(--tw-text-opacity) );
}

.btn-outline.btn-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity) );
}

.btn-outline.btn-warning:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--wac, var(--nc)) / var(--tw-text-opacity) );
}

.btn-outline.btn-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity) );
}

.btn-outline.btn-error:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--erc, var(--nc)) / var(--tw-text-opacity) );
}

.btn-disabled, .btn-disabled:hover, .btn[disabled], .btn[disabled]:hover {
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
  --tw-bg-opacity: .2;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  --tw-text-opacity: .2;
}

.btn.loading.btn-square:before, .btn.loading.btn-circle:before {
  margin-right: 0;
}

.btn.loading.btn-xl:before, .btn.loading.btn-lg:before {
  height: 1.25rem;
  width: 1.25rem;
}

.btn.loading.btn-sm:before, .btn.loading.btn-xs:before {
  height: .75rem;
  width: .75rem;
}

.btn-group > input[type="radio"]:checked.btn, .btn-group > .btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.btn-group > input[type="radio"]:checked.btn:focus-visible, .btn-group > .btn-active:focus-visible {
  outline: 2px solid hsl(var(--p) );
}

.btn-group:not(.btn-group-vertical) > .btn:not(:first-of-type) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.btn-group:not(.btn-group-vertical) > .btn:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-of-type) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .95) );
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.card:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.card.bordered, .card-bordered {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity) );
}

.card.compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-title {
  align-items: center;
  gap: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  display: flex;
}

.carousel::-webkit-scrollbar {
  display: none;
}

.checkbox:focus-visible {
  outline: 2px solid hsl(var(--bc) );
  outline-offset: 2px;
}

.checkbox:checked, .checkbox[checked="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  animation: checkmark var(--animation-input, .2s) ease-in-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(-45deg, transparent 65%, hsl(var(--chkbg) ) 65.99%), linear-gradient(45deg, transparent 75%, hsl(var(--chkbg) ) 75.99%), linear-gradient(-45deg, hsl(var(--chkbg) ) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkbg) ) 30%, hsl(var(--chkfg) ) 30.99%, hsl(var(--chkfg) ) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkfg) ) 50%, hsl(var(--chkbg) ) 50.99%);
}

.checkbox:indeterminate {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  animation: checkmark var(--animation-input, .2s) ease-in-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, transparent 80%, hsl(var(--chkbg) ) 80%), linear-gradient(-90deg, transparent 80%, hsl(var(--chkbg) ) 80%), linear-gradient(0deg, hsl(var(--chkbg) ) 43%, hsl(var(--chkfg) ) 43%, hsl(var(--chkfg) ) 57%, hsl(var(--chkbg) ) 57%);
}

.checkbox-primary {
  --chkbg: var(--p);
  --chkfg: var(--pc);
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
}

.checkbox-primary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
}

.checkbox-primary:focus-visible {
  outline: 2px solid hsl(var(--p) );
}

.checkbox-primary:checked, .checkbox-primary[checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.checkbox-secondary {
  --chkbg: var(--s);
  --chkfg: var(--sc);
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
}

.checkbox-secondary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
}

.checkbox-secondary:focus-visible {
  outline: 2px solid hsl(var(--s) );
}

.checkbox-secondary:checked, .checkbox-secondary[checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity) );
}

.checkbox-accent {
  --chkbg: var(--a);
  --chkfg: var(--ac);
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
}

.checkbox-accent:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
}

.checkbox-accent:focus-visible {
  outline: 2px solid hsl(var(--a) );
}

.checkbox-accent:checked, .checkbox-accent[checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity) );
}

.checkbox:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  opacity: .2;
  border-color: #0000;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

.checkbox-mark {
  display: none;
}

body[dir="rtl"] .checkbox {
  --chkbg: var(--bc);
  --chkfg: var(--b1);
}

body[dir="rtl"] .checkbox:checked, body[dir="rtl"] .checkbox[checked="true"] {
  background-image: linear-gradient(45deg, transparent 65%, hsl(var(--chkbg) ) 65.99%), linear-gradient(-45deg, transparent 75%, hsl(var(--chkbg) ) 75.99%), linear-gradient(45deg, hsl(var(--chkbg) ) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkbg) ) 30%, hsl(var(--chkfg) ) 30.99%, hsl(var(--chkfg) ) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkfg) ) 50%, hsl(var(--chkbg) ) 50.99%);
}

.collapse:focus-visible {
  outline: 2px solid hsl(var(--nf) );
  outline-offset: 2px;
}

.collapse-arrow .collapse-title:after {
  height: .5rem;
  width: .5rem;
  content: "";
  transform-origin: 75% 75%;
  pointer-events: none;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: 1.4rem;
  right: 1.4rem;
  transform: rotate(45deg);
  box-shadow: 2px 2px;
}

.collapse-plus .collapse-title:after {
  height: .5rem;
  width: .5rem;
  content: "+";
  pointer-events: none;
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  top: .9rem;
  right: 1.4rem;
}

.collapse:not(.collapse-open):not(.collapse-close) input[type="checkbox"], .collapse:not(.collapse-open):not(.collapse-close) .collapse-title {
  cursor: pointer;
}

.collapse:focus:not(.collapse-open):not(.collapse-close) .collapse-title {
  cursor: unset;
}

.collapse-title, .collapse > input[type="checkbox"] {
  width: 100%;
  min-height: 3.75rem;
  padding: 1rem 3rem 1rem 1rem;
  transition: background-color .2s ease-in-out;
}

.collapse-open :where(.collapse-content), .collapse:focus:not(.collapse-close) :where(.collapse-content), .collapse:not(.collapse-close) :where(input[type="checkbox"]:checked ~ .collapse-content) {
  padding-bottom: 1rem;
  transition: padding .2s ease-in-out, background-color .2s ease-in-out;
}

.collapse-open.collapse-arrow .collapse-title:after, .collapse-arrow:focus:not(.collapse-close) .collapse-title:after, .collapse-arrow:not(.collapse-close) input[type="checkbox"]:checked ~ .collapse-title:after {
  transform: rotate(225deg);
}

.collapse-open.collapse-plus .collapse-title:after, .collapse-plus:focus:not(.collapse-close) .collapse-title:after, .collapse-plus:not(.collapse-close) input[type="checkbox"]:checked ~ .collapse-title:after {
  content: "−";
}

.divider:before, .divider:after {
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  --tw-bg-opacity: .1;
}

.divider:not(:empty) {
  gap: 1rem;
}

.drawer.drawer-end > .drawer-toggle:checked ~ .drawer-content {
  --tw-translate-x: -.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.drawer-toggle:checked ~ .drawer-content {
  --tw-translate-x: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button {
  outline: 2px solid hsl(var(--nf) );
  outline-offset: 2px;
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-primary {
  outline: 2px solid hsl(var(--p) );
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-secondary {
  outline: 2px solid hsl(var(--s) );
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-accent {
  outline: 2px solid hsl(var(--a) );
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-info {
  outline: 2px solid hsl(var(--in) );
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-success {
  outline: 2px solid hsl(var(--su) );
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-warning {
  outline: 2px solid hsl(var(--wa) );
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-error {
  outline: 2px solid hsl(var(--er) );
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button.glass, .drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-ghost, .drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-link {
  outline: 2px solid;
}

.dropdown.dropdown-open .dropdown-content, .dropdown.dropdown-hover:hover .dropdown-content, .dropdown:focus .dropdown-content, .dropdown:focus-within .dropdown-content {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.footer-title {
  text-transform: uppercase;
  opacity: .5;
  margin-bottom: .5rem;
  font-weight: 700;
}

.label-text {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  font-size: .875rem;
  line-height: 1.25rem;
}

.label-text-alt {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  font-size: .75rem;
  line-height: 1rem;
}

.label a:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
}

.input[list]::-webkit-calendar-picker-indicator {
  line-height: 1em;
}

.input-bordered {
  --tw-border-opacity: .2;
}

.input:focus {
  outline: 2px solid hsla(var(--bc) / .2);
  outline-offset: 2px;
}

.input-ghost {
  --tw-bg-opacity: .05;
}

.input-ghost:focus {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  box-shadow: none;
}

.input-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
}

.input-primary:focus {
  outline: 2px solid hsl(var(--p) );
}

.input-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
}

.input-secondary:focus {
  outline: 2px solid hsl(var(--s) );
}

.input-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
}

.input-accent:focus {
  outline: 2px solid hsl(var(--a) );
}

.input-info {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity) );
}

.input-info:focus {
  outline: 2px solid hsl(var(--in) );
}

.input-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity) );
}

.input-success:focus {
  outline: 2px solid hsl(var(--su) );
}

.input-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity) );
}

.input-warning:focus {
  outline: 2px solid hsl(var(--wa) );
}

.input-error {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity) );
}

.input-error:focus {
  outline: 2px solid hsl(var(--er) );
}

.input-disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
  --tw-text-opacity: .2;
}

.input-disabled::placeholder, .input[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity) );
  --tw-placeholder-opacity: .2;
}

.link-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity) );
}

.link-primary:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--pf, var(--p)) / var(--tw-text-opacity) );
}

.link-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity) );
}

.link-secondary:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--sf, var(--s)) / var(--tw-text-opacity) );
}

.link-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity) );
}

.link-accent:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--af, var(--a)) / var(--tw-text-opacity) );
}

.link-neutral {
  --tw-text-opacity: 1;
  color: hsl(var(--n) / var(--tw-text-opacity) );
}

.link-neutral:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--nf, var(--n)) / var(--tw-text-opacity) );
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.mask-squircle {
  -webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCiAgPHBhdGggZD0iTSAxMDAgMCBDIDIwIDAgMCAyMCAwIDEwMCBDIDAgMTgwIDIwIDIwMCAxMDAgMjAwIEMgMTgwIDIwMCAyMDAgMTgwIDIwMCAxMDAgQyAyMDAgMjAgMTgwIDAgMTAwIDAgWiIvPgoKPC9zdmc+");
  mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KCiAgPHBhdGggZD0iTSAxMDAgMCBDIDIwIDAgMCAyMCAwIDEwMCBDIDAgMTgwIDIwIDIwMCAxMDAgMjAwIEMgMTgwIDIwMCAyMDAgMTgwIDIwMCAxMDAgQyAyMDAgMjAgMTgwIDAgMTAwIDAgWiIvPgoKPC9zdmc+");
}

.mask-decagon {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTkycHgiIGhlaWdodD0iMjAwcHgiIHZpZXdCb3g9IjAgMCAxOTIgMjAwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+ZGVjYWdvbjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwb2x5Z29uIGlkPSJkZWNhZ29uIiBmaWxsPSIjMDAwMDAwIiBwb2ludHM9Ijk2IDAgMTU0Ljc3ODUyNSAxOS4wOTgzMDA2IDE5MS4xMDU2NTIgNjkuMDk4MzAwNiAxOTEuMTA1NjUyIDEzMC45MDE2OTkgMTU0Ljc3ODUyNSAxODAuOTAxNjk5IDk2IDIwMCAzNy4yMjE0NzQ4IDE4MC45MDE2OTkgMC44OTQzNDgzNyAxMzAuOTAxNjk5IDAuODk0MzQ4MzcgNjkuMDk4MzAwNiAzNy4yMjE0NzQ4IDE5LjA5ODMwMDYiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTkycHgiIGhlaWdodD0iMjAwcHgiIHZpZXdCb3g9IjAgMCAxOTIgMjAwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+ZGVjYWdvbjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwb2x5Z29uIGlkPSJkZWNhZ29uIiBmaWxsPSIjMDAwMDAwIiBwb2ludHM9Ijk2IDAgMTU0Ljc3ODUyNSAxOS4wOTgzMDA2IDE5MS4xMDU2NTIgNjkuMDk4MzAwNiAxOTEuMTA1NjUyIDEzMC45MDE2OTkgMTU0Ljc3ODUyNSAxODAuOTAxNjk5IDk2IDIwMCAzNy4yMjE0NzQ4IDE4MC45MDE2OTkgMC44OTQzNDgzNyAxMzAuOTAxNjk5IDAuODk0MzQ4MzcgNjkuMDk4MzAwNiAzNy4yMjE0NzQ4IDE5LjA5ODMwMDYiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
}

.mask-diamond {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMjAwcHgiIHZpZXdCb3g9IjAgMCAyMDAgMjAwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+ZGlhbW9uZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwb2x5Z29uIGlkPSJkaWFtb25kIiBmaWxsPSIjMDAwMDAwIiBwb2ludHM9IjEwMCAwIDIwMCAxMDAgMTAwIDIwMCAwIDEwMCI+PC9wb2x5Z29uPgogICAgPC9nPgo8L3N2Zz4=");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMjAwcHgiIHZpZXdCb3g9IjAgMCAyMDAgMjAwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+ZGlhbW9uZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwb2x5Z29uIGlkPSJkaWFtb25kIiBmaWxsPSIjMDAwMDAwIiBwb2ludHM9IjEwMCAwIDIwMCAxMDAgMTAwIDIwMCAwIDEwMCI+PC9wb2x5Z29uPgogICAgPC9nPgo8L3N2Zz4=");
}

.mask-heart {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMTg1cHgiIHZpZXdCb3g9IjAgMCAyMDAgMTg1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+aGVhcnQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aCBkPSJNMTAwLDE4NC42MDU1MzQgQzk2LjkxMjE3MTYsMTg0LjYwMTYzNSA5My44OTY5NzMzLDE4My42Njg1OTggOTEuMzQ2NjE4MiwxODEuOTI3NzkzIEM1My41NjQ5ODA0LDE1Ni4yODAxMjMgMzcuMjA1Mjc2NCwxMzguNjk0NTIzIDI4LjE4MTcxOTQsMTI3LjY5OTkxNyBDOC45NTE5NzYyNiwxMDQuMjYzNjY3IC0wLjI1NDI2MzI3Myw4MC4yMDI0NTEzIDAuMDA1MzM4MjU5MzEsNTQuMTQ2MTQ5MyBDMC4zMDgyMDY3MTQsMjQuMjg3MTY1NiAyNC4yNjM2NTkzLDAgNTMuNDA2MzM1LDAgQzc0LjU5NzUxMiwwIDg5LjI3NDYxMzQsMTEuOTM2ODYzMSA5Ny44MjIyMzQzLDIxLjg3ODY0MDMgQzk4LjM3MDA4MTIsMjIuNTA5NDMgOTkuMTY0NTE5NiwyMi44NzE2ODg5IDEwMCwyMi44NzE2ODg5IEMxMDAuODM1NDg2LDIyLjg3MTY4ODkgMTAxLjYyOTkyNCwyMi41MDk0MyAxMDIuMTc3NzcxLDIxLjg3ODY0MDMgQzExMC43MjUzOTIsMTEuOTI3MjQ4MiAxMjUuNDAyNDkzLDAgMTQ2LjU5MzY3LDAgQzE3NS43MzYzNDYsMCAxOTkuNjkxNzk5LDI0LjI4NzE2NTYgMTk5Ljk5NDY2Nyw1NC4xNTA5NTY3IEMyMDAuMjU0MjY5LDgwLjIxMjA2NjEgMTkxLjAzODQxNCwxMDQuMjczMjgyIDE3MS44MTgyODYsMTI3LjcwNDcyNCBDMTYyLjc5NDcyOSwxMzguNjk5MzMgMTQ2LjQzNTAyNSwxNTYuMjg0OTMgMTA4LjY1MzM4NywxODEuOTMyNiBDMTA2LjEwMjQ4NCwxODMuNjcxNzA0IDEwMy4wODczMjksMTg0LjYwMzA1MiAxMDAsMTg0LjYwNTUzNCBaIiBpZD0iaGVhcnQiIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgPC9nPgo8L3N2Zz4=");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMTg1cHgiIHZpZXdCb3g9IjAgMCAyMDAgMTg1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+aGVhcnQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aCBkPSJNMTAwLDE4NC42MDU1MzQgQzk2LjkxMjE3MTYsMTg0LjYwMTYzNSA5My44OTY5NzMzLDE4My42Njg1OTggOTEuMzQ2NjE4MiwxODEuOTI3NzkzIEM1My41NjQ5ODA0LDE1Ni4yODAxMjMgMzcuMjA1Mjc2NCwxMzguNjk0NTIzIDI4LjE4MTcxOTQsMTI3LjY5OTkxNyBDOC45NTE5NzYyNiwxMDQuMjYzNjY3IC0wLjI1NDI2MzI3Myw4MC4yMDI0NTEzIDAuMDA1MzM4MjU5MzEsNTQuMTQ2MTQ5MyBDMC4zMDgyMDY3MTQsMjQuMjg3MTY1NiAyNC4yNjM2NTkzLDAgNTMuNDA2MzM1LDAgQzc0LjU5NzUxMiwwIDg5LjI3NDYxMzQsMTEuOTM2ODYzMSA5Ny44MjIyMzQzLDIxLjg3ODY0MDMgQzk4LjM3MDA4MTIsMjIuNTA5NDMgOTkuMTY0NTE5NiwyMi44NzE2ODg5IDEwMCwyMi44NzE2ODg5IEMxMDAuODM1NDg2LDIyLjg3MTY4ODkgMTAxLjYyOTkyNCwyMi41MDk0MyAxMDIuMTc3NzcxLDIxLjg3ODY0MDMgQzExMC43MjUzOTIsMTEuOTI3MjQ4MiAxMjUuNDAyNDkzLDAgMTQ2LjU5MzY3LDAgQzE3NS43MzYzNDYsMCAxOTkuNjkxNzk5LDI0LjI4NzE2NTYgMTk5Ljk5NDY2Nyw1NC4xNTA5NTY3IEMyMDAuMjU0MjY5LDgwLjIxMjA2NjEgMTkxLjAzODQxNCwxMDQuMjczMjgyIDE3MS44MTgyODYsMTI3LjcwNDcyNCBDMTYyLjc5NDcyOSwxMzguNjk5MzMgMTQ2LjQzNTAyNSwxNTYuMjg0OTMgMTA4LjY1MzM4NywxODEuOTMyNiBDMTA2LjEwMjQ4NCwxODMuNjcxNzA0IDEwMy4wODczMjksMTg0LjYwMzA1MiAxMDAsMTg0LjYwNTUzNCBaIiBpZD0iaGVhcnQiIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPgogICAgPC9nPgo8L3N2Zz4=");
}

.mask-hexagon {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTgycHgiIGhlaWdodD0iMjAxcHgiIHZpZXdCb3g9IjAgMCAxODIgMjAxIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+aGV4YWdvbjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik01NS43ODU3ODg5LDE5MS40MDAyMDkgQzQ2LjU5MDM4NjYsMTkxLjQwMDIwOSAzNS43MjMwOTMsMTg0LjcxMjY0NCAzMC43MDc0MTksMTc3LjE4OTEzMyBMLTUuMjM4MjQ0NTEsMTE1LjMyOTE1NCBDLTEwLjI1MzkxODUsMTA2Ljk2OTY5NyAtMTAuMjUzOTE4NSw5NC40MzA1MTIgLTUuMjM4MjQ0NTEsODYuMDcxMDU1NCBMMzAuNzA3NDE5LDI0LjIxMTA3NjMgQzM1LjcyMzA5MywxNS44NTE2MTk2IDQ2LjU5MDM4NjYsMTAgNTUuNzg1Nzg4OSwxMCBMMTI2Ljg0MTE3LDEwIEMxMzYuMDM2NTczLDEwIDE0Ni45MDM4NjYsMTYuNjg3NTY1MyAxNTEuOTE5NTQsMjQuMjExMDc2MyBMMTg3Ljg2NTIwNCw4Ni4wNzEwNTU0IEMxOTIuMDQ0OTMyLDk0LjQzMDUxMiAxOTIuMDQ0OTMyLDEwNi45Njk2OTcgMTg3Ljg2NTIwNCwxMTUuMzI5MTU0IEwxNTEuOTE5NTQsMTc3LjE4OTEzMyBDMTQ3LjczOTgxMiwxODUuNTQ4NTg5IDEzNi4wMzY1NzMsMTkxLjQwMDIwOSAxMjYuODQxMTcsMTkxLjQwMDIwOSBMNTUuNzg1Nzg4OSwxOTEuNDAwMjA5IFoiIGlkPSJoZXhhZ29uIiBmaWxsPSIjMDAwMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDkxLjAwMDAwMCwgMTAwLjcwMDEwNCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTkxLjAwMDAwMCwgLTEwMC43MDAxMDQpICI+PC9wYXRoPgogICAgPC9nPgo8L3N2Zz4=");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTgycHgiIGhlaWdodD0iMjAxcHgiIHZpZXdCb3g9IjAgMCAxODIgMjAxIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+aGV4YWdvbjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik01NS43ODU3ODg5LDE5MS40MDAyMDkgQzQ2LjU5MDM4NjYsMTkxLjQwMDIwOSAzNS43MjMwOTMsMTg0LjcxMjY0NCAzMC43MDc0MTksMTc3LjE4OTEzMyBMLTUuMjM4MjQ0NTEsMTE1LjMyOTE1NCBDLTEwLjI1MzkxODUsMTA2Ljk2OTY5NyAtMTAuMjUzOTE4NSw5NC40MzA1MTIgLTUuMjM4MjQ0NTEsODYuMDcxMDU1NCBMMzAuNzA3NDE5LDI0LjIxMTA3NjMgQzM1LjcyMzA5MywxNS44NTE2MTk2IDQ2LjU5MDM4NjYsMTAgNTUuNzg1Nzg4OSwxMCBMMTI2Ljg0MTE3LDEwIEMxMzYuMDM2NTczLDEwIDE0Ni45MDM4NjYsMTYuNjg3NTY1MyAxNTEuOTE5NTQsMjQuMjExMDc2MyBMMTg3Ljg2NTIwNCw4Ni4wNzEwNTU0IEMxOTIuMDQ0OTMyLDk0LjQzMDUxMiAxOTIuMDQ0OTMyLDEwNi45Njk2OTcgMTg3Ljg2NTIwNCwxMTUuMzI5MTU0IEwxNTEuOTE5NTQsMTc3LjE4OTEzMyBDMTQ3LjczOTgxMiwxODUuNTQ4NTg5IDEzNi4wMzY1NzMsMTkxLjQwMDIwOSAxMjYuODQxMTcsMTkxLjQwMDIwOSBMNTUuNzg1Nzg4OSwxOTEuNDAwMjA5IFoiIGlkPSJoZXhhZ29uIiBmaWxsPSIjMDAwMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDkxLjAwMDAwMCwgMTAwLjcwMDEwNCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTkxLjAwMDAwMCwgLTEwMC43MDAxMDQpICI+PC9wYXRoPgogICAgPC9nPgo8L3N2Zz4=");
}

.mask-hexagon-2 {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMTgycHgiIHZpZXdCb3g9IjAgMCAyMDAgMTgyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+aGV4YWdvbi0yPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTY0Ljc4NTc4ODksMTgxLjQwMDIwOSBDNTUuNTkwMzg2NiwxODEuNDAwMjA5IDQ0LjcyMzA5MywxNzQuNzEyNjQ0IDM5LjcwNzQxOSwxNjcuMTg5MTMzIEwzLjc2MTc1NTQ5LDEwNS4zMjkxNTQgQy0xLjI1MzkxODUsOTYuOTY5Njk3IC0xLjI1MzkxODUsODQuNDMwNTEyIDMuNzYxNzU1NDksNzYuMDcxMDU1NCBMMzkuNzA3NDE5LDE0LjIxMTA3NjMgQzQ0LjcyMzA5Myw1Ljg1MTYxOTY0IDU1LjU5MDM4NjYsMCA2NC43ODU3ODg5LDAgTDEzNS44NDExNywwIEMxNDUuMDM2NTczLDAgMTU1LjkwMzg2Niw2LjY4NzU2NTMxIDE2MC45MTk1NCwxNC4yMTEwNzYzIEwxOTYuODY1MjA0LDc2LjA3MTA1NTQgQzIwMS4wNDQ5MzIsODQuNDMwNTEyIDIwMS4wNDQ5MzIsOTYuOTY5Njk3IDE5Ni44NjUyMDQsMTA1LjMyOTE1NCBMMTYwLjkxOTU0LDE2Ny4xODkxMzMgQzE1Ni43Mzk4MTIsMTc1LjU0ODU4OSAxNDUuMDM2NTczLDE4MS40MDAyMDkgMTM1Ljg0MTE3LDE4MS40MDAyMDkgTDY0Ljc4NTc4ODksMTgxLjQwMDIwOSBaIiBpZD0iaGV4YWdvbi0yIiBmaWxsPSIjMDAwMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iPjwvcGF0aD4KICAgIDwvZz4KPC9zdmc+");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMTgycHgiIHZpZXdCb3g9IjAgMCAyMDAgMTgyIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+aGV4YWdvbi0yPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTY0Ljc4NTc4ODksMTgxLjQwMDIwOSBDNTUuNTkwMzg2NiwxODEuNDAwMjA5IDQ0LjcyMzA5MywxNzQuNzEyNjQ0IDM5LjcwNzQxOSwxNjcuMTg5MTMzIEwzLjc2MTc1NTQ5LDEwNS4zMjkxNTQgQy0xLjI1MzkxODUsOTYuOTY5Njk3IC0xLjI1MzkxODUsODQuNDMwNTEyIDMuNzYxNzU1NDksNzYuMDcxMDU1NCBMMzkuNzA3NDE5LDE0LjIxMTA3NjMgQzQ0LjcyMzA5Myw1Ljg1MTYxOTY0IDU1LjU5MDM4NjYsMCA2NC43ODU3ODg5LDAgTDEzNS44NDExNywwIEMxNDUuMDM2NTczLDAgMTU1LjkwMzg2Niw2LjY4NzU2NTMxIDE2MC45MTk1NCwxNC4yMTEwNzYzIEwxOTYuODY1MjA0LDc2LjA3MTA1NTQgQzIwMS4wNDQ5MzIsODQuNDMwNTEyIDIwMS4wNDQ5MzIsOTYuOTY5Njk3IDE5Ni44NjUyMDQsMTA1LjMyOTE1NCBMMTYwLjkxOTU0LDE2Ny4xODkxMzMgQzE1Ni43Mzk4MTIsMTc1LjU0ODU4OSAxNDUuMDM2NTczLDE4MS40MDAyMDkgMTM1Ljg0MTE3LDE4MS40MDAyMDkgTDY0Ljc4NTc4ODksMTgxLjQwMDIwOSBaIiBpZD0iaGV4YWdvbi0yIiBmaWxsPSIjMDAwMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iPjwvcGF0aD4KICAgIDwvZz4KPC9zdmc+");
}

.mask-circle {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMjAwcHgiIHZpZXdCb3g9IjAgMCAyMDAgMjAwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+T3ZhbDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIGZpbGw9IiMwMDAwMDAiIGN4PSIxMDAiIGN5PSIxMDAiIHI9IjEwMCI+PC9jaXJjbGU+CiAgICA8L2c+Cjwvc3ZnPg==");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMjAwcHgiIHZpZXdCb3g9IjAgMCAyMDAgMjAwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+T3ZhbDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIGZpbGw9IiMwMDAwMDAiIGN4PSIxMDAiIGN5PSIxMDAiIHI9IjEwMCI+PC9jaXJjbGU+CiAgICA8L2c+Cjwvc3ZnPg==");
}

.mask-parallelogram {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMTU0cHgiIHZpZXdCb3g9IjAgMCAyMDAgMTU0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+cGFyYWxsZWxvZ3JhbTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwb2x5Z29uIGlkPSJwYXJhbGxlbG9ncmFtIiBmaWxsPSIjMDAwMDAwIiBwb2ludHM9IjQ2LjE1Mzg0NjIgMCAyMDAgMCAxNTMuODQ2MTU0IDE1My44NDYxNTQgMCAxNTMuODQ2MTU0Ij48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMTU0cHgiIHZpZXdCb3g9IjAgMCAyMDAgMTU0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+cGFyYWxsZWxvZ3JhbTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwb2x5Z29uIGlkPSJwYXJhbGxlbG9ncmFtIiBmaWxsPSIjMDAwMDAwIiBwb2ludHM9IjQ2LjE1Mzg0NjIgMCAyMDAgMCAxNTMuODQ2MTU0IDE1My44NDYxNTQgMCAxNTMuODQ2MTU0Ij48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
}

.mask-parallelogram-2 {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMTU0cHgiIHZpZXdCb3g9IjAgMCAyMDAgMTU0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+cGFyYWxsZWxvZ3JhbS0yPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhcmFsbGVsb2dyYW0tMiIgZmlsbD0iIzAwMDAwMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAwLjAwMDAwMCwgNzYuOTIzMDc3KSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC0xMDAuMDAwMDAwLCAtNzYuOTIzMDc3KSAiIHBvaW50cz0iNDYuMTUzODQ2MiAwIDIwMCAwIDE1My44NDYxNTQgMTUzLjg0NjE1NCAwIDE1My44NDYxNTQiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMTU0cHgiIHZpZXdCb3g9IjAgMCAyMDAgMTU0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+cGFyYWxsZWxvZ3JhbS0yPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhcmFsbGVsb2dyYW0tMiIgZmlsbD0iIzAwMDAwMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAwLjAwMDAwMCwgNzYuOTIzMDc3KSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC0xMDAuMDAwMDAwLCAtNzYuOTIzMDc3KSAiIHBvaW50cz0iNDYuMTUzODQ2MiAwIDIwMCAwIDE1My44NDYxNTQgMTUzLjg0NjE1NCAwIDE1My44NDYxNTQiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
}

.mask-parallelogram-3 {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTU0cHgiIGhlaWdodD0iMjAxcHgiIHZpZXdCb3g9IjAgMCAxNTQgMjAxIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+cGFyYWxsZWxvZ3JhbS0zPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhcmFsbGVsb2dyYW0tMyIgZmlsbD0iIzAwMDAwMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzcuMDAwMDAwLCAxMDAuOTIzMDc3KSBzY2FsZSgtMSwgMSkgcm90YXRlKDkwLjAwMDAwMCkgdHJhbnNsYXRlKC03Ny4wMDAwMDAsIC0xMDAuOTIzMDc3KSAiIHBvaW50cz0iMjMuMTUzODQ2MiAyNCAxNzcgMjQgMTMwLjg0NjE1NCAxNzcuODQ2MTU0IC0yMyAxNzcuODQ2MTU0Ij48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTU0cHgiIGhlaWdodD0iMjAxcHgiIHZpZXdCb3g9IjAgMCAxNTQgMjAxIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+cGFyYWxsZWxvZ3JhbS0zPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhcmFsbGVsb2dyYW0tMyIgZmlsbD0iIzAwMDAwMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzcuMDAwMDAwLCAxMDAuOTIzMDc3KSBzY2FsZSgtMSwgMSkgcm90YXRlKDkwLjAwMDAwMCkgdHJhbnNsYXRlKC03Ny4wMDAwMDAsIC0xMDAuOTIzMDc3KSAiIHBvaW50cz0iMjMuMTUzODQ2MiAyNCAxNzcgMjQgMTMwLjg0NjE1NCAxNzcuODQ2MTU0IC0yMyAxNzcuODQ2MTU0Ij48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
}

.mask-parallelogram-4 {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTU0cHgiIGhlaWdodD0iMjAxcHgiIHZpZXdCb3g9IjAgMCAxNTQgMjAxIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+cGFyYWxsZWxvZ3JhbS00PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhcmFsbGVsb2dyYW0tNCIgZmlsbD0iIzAwMDAwMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzcuMDAwMDAwLCAxMDAuOTIzMDc3KSByb3RhdGUoOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTc3LjAwMDAwMCwgLTEwMC45MjMwNzcpICIgcG9pbnRzPSIyMy4xNTM4NDYyIDI0IDE3NyAyNCAxMzAuODQ2MTU0IDE3Ny44NDYxNTQgLTIzIDE3Ny44NDYxNTQiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTU0cHgiIGhlaWdodD0iMjAxcHgiIHZpZXdCb3g9IjAgMCAxNTQgMjAxIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+cGFyYWxsZWxvZ3JhbS00PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9InBhcmFsbGVsb2dyYW0tNCIgZmlsbD0iIzAwMDAwMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzcuMDAwMDAwLCAxMDAuOTIzMDc3KSByb3RhdGUoOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTc3LjAwMDAwMCwgLTEwMC45MjMwNzcpICIgcG9pbnRzPSIyMy4xNTM4NDYyIDI0IDE3NyAyNCAxMzAuODQ2MTU0IDE3Ny44NDYxNTQgLTIzIDE3Ny44NDYxNTQiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
}

.mask-pentagon {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTkycHgiIGhlaWdodD0iMTgxcHgiIHZpZXdCb3g9IjAgMCAxOTIgMTgxIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+cGVudGFnb248L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cG9seWdvbiBpZD0icGVudGFnb24iIGZpbGw9IiMwMDAwMDAiIHBvaW50cz0iOTYgMCAxOTEuMTA1NjUyIDY5LjA5ODMwMDYgMTU0Ljc3ODUyNSAxODAuOTAxNjk5IDM3LjIyMTQ3NDggMTgwLjkwMTY5OSAwLjg5NDM0ODM3IDY5LjA5ODMwMDYiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTkycHgiIGhlaWdodD0iMTgxcHgiIHZpZXdCb3g9IjAgMCAxOTIgMTgxIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+cGVudGFnb248L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cG9seWdvbiBpZD0icGVudGFnb24iIGZpbGw9IiMwMDAwMDAiIHBvaW50cz0iOTYgMCAxOTEuMTA1NjUyIDY5LjA5ODMwMDYgMTU0Ljc3ODUyNSAxODAuOTAxNjk5IDM3LjIyMTQ3NDggMTgwLjkwMTY5OSAwLjg5NDM0ODM3IDY5LjA5ODMwMDYiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
}

.mask-square {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMjAwcHgiIHZpZXdCb3g9IjAgMCAyMDAgMjAwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+c3F1YXJlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHJlY3QgaWQ9InNxdWFyZSIgZmlsbD0iIzAwMDAwMCIgeD0iMCIgeT0iMCIgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiPjwvcmVjdD4KICAgIDwvZz4KPC9zdmc+");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjAwcHgiIGhlaWdodD0iMjAwcHgiIHZpZXdCb3g9IjAgMCAyMDAgMjAwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+c3F1YXJlPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHJlY3QgaWQ9InNxdWFyZSIgZmlsbD0iIzAwMDAwMCIgeD0iMCIgeT0iMCIgd2lkdGg9IjIwMCIgaGVpZ2h0PSIyMDAiPjwvcmVjdD4KICAgIDwvZz4KPC9zdmc+");
}

.mask-star {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTkycHgiIGhlaWdodD0iMTgwcHgiIHZpZXdCb3g9IjAgMCAxOTIgMTgwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+c3RhcjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwb2x5Z29uIGlkPSJzdGFyIiBmaWxsPSIjMDAwMDAwIiBwb2ludHM9Ijk2IDEzNy4yNjMzOTMgMzcuMjIxNDc0OCAxNzkuMjg2NTA2IDU5LjM4NDMyNDEgMTEwLjg5ODA3MyAwLjg5NDM0ODM3IDY4LjQ4MTM1MTUgNzMuMzcwMjY3OCA2OC4yMzgwODgyIDk2IDAgMTE4LjYyOTczMiA2OC4yMzgwODgyIDE5MS4xMDU2NTIgNjguNDgxMzUxNSAxMzIuNjE1Njc2IDExMC44OTgwNzMgMTU0Ljc3ODUyNSAxNzkuMjg2NTA2Ij48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTkycHgiIGhlaWdodD0iMTgwcHgiIHZpZXdCb3g9IjAgMCAxOTIgMTgwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+c3RhcjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwb2x5Z29uIGlkPSJzdGFyIiBmaWxsPSIjMDAwMDAwIiBwb2ludHM9Ijk2IDEzNy4yNjMzOTMgMzcuMjIxNDc0OCAxNzkuMjg2NTA2IDU5LjM4NDMyNDEgMTEwLjg5ODA3MyAwLjg5NDM0ODM3IDY4LjQ4MTM1MTUgNzMuMzcwMjY3OCA2OC4yMzgwODgyIDk2IDAgMTE4LjYyOTczMiA2OC4yMzgwODgyIDE5MS4xMDU2NTIgNjguNDgxMzUxNSAxMzIuNjE1Njc2IDExMC44OTgwNzMgMTU0Ljc3ODUyNSAxNzkuMjg2NTA2Ij48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
}

.mask-star-2 {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTkycHgiIGhlaWdodD0iMTgwcHgiIHZpZXdCb3g9IjAgMCAxOTIgMTgwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+c3Rhci0yPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9InN0YXItMiIgZmlsbD0iIzAwMDAwMCIgcG9pbnRzPSI5NiAxNTMuMDQzNjYxIDM3LjIyMTQ3NDggMTc5LjI4NjUwNiA0NC4yNDExOTA0IDExNS43NzQ0NDQgMC44OTQzNDgzNyA2OC40ODEzNTE1IDY0LjAxMTI5NjUgNTUuNDcxNTgyOCA5NiAwIDEyNy45ODg3MDQgNTUuNDcxNTgyOCAxOTEuMTA1NjUyIDY4LjQ4MTM1MTUgMTQ3Ljc1ODgxIDExNS43NzQ0NDQgMTU0Ljc3ODUyNSAxNzkuMjg2NTA2Ij48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTkycHgiIGhlaWdodD0iMTgwcHgiIHZpZXdCb3g9IjAgMCAxOTIgMTgwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+c3Rhci0yPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9InN0YXItMiIgZmlsbD0iIzAwMDAwMCIgcG9pbnRzPSI5NiAxNTMuMDQzNjYxIDM3LjIyMTQ3NDggMTc5LjI4NjUwNiA0NC4yNDExOTA0IDExNS43NzQ0NDQgMC44OTQzNDgzNyA2OC40ODEzNTE1IDY0LjAxMTI5NjUgNTUuNDcxNTgyOCA5NiAwIDEyNy45ODg3MDQgNTUuNDcxNTgyOCAxOTEuMTA1NjUyIDY4LjQ4MTM1MTUgMTQ3Ljc1ODgxIDExNS43NzQ0NDQgMTU0Ljc3ODUyNSAxNzkuMjg2NTA2Ij48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
}

.mask-triangle {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTc0cHgiIGhlaWdodD0iMTQ5cHgiIHZpZXdCb3g9IjAgMCAxNzQgMTQ5IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+dHJpYW5nbGU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cG9seWdvbiBpZD0idHJpYW5nbGUiIGZpbGw9IiMwMDAwMDAiIHBvaW50cz0iODcgMTQ4LjQ3NjE3NyAwLjM5NzQ1OTYyMiAxNDguNjYwNzE0IDQzLjg1OTk4MzcgNzQuNDIyNjI1OSA4NyAyLjg0MjE3MDk0ZS0xNCAxMzAuMTQwMDE2IDc0LjQyMjYyNTkgMTczLjYwMjU0IDE0OC42NjA3MTQiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTc0cHgiIGhlaWdodD0iMTQ5cHgiIHZpZXdCb3g9IjAgMCAxNzQgMTQ5IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+dHJpYW5nbGU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cG9seWdvbiBpZD0idHJpYW5nbGUiIGZpbGw9IiMwMDAwMDAiIHBvaW50cz0iODcgMTQ4LjQ3NjE3NyAwLjM5NzQ1OTYyMiAxNDguNjYwNzE0IDQzLjg1OTk4MzcgNzQuNDIyNjI1OSA4NyAyLjg0MjE3MDk0ZS0xNCAxMzAuMTQwMDE2IDc0LjQyMjYyNTkgMTczLjYwMjU0IDE0OC42NjA3MTQiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
}

.mask-triangle-2 {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTc0cHgiIGhlaWdodD0iMTUwcHgiIHZpZXdCb3g9IjAgMCAxNzQgMTUwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+dHJpYW5nbGUtYm90dG9tPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9InRyaWFuZ2xlLWJvdHRvbSIgZmlsbD0iIzAwMDAwMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODcuMDAwMDAwLCA1MC4xMDcxNDMpIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC04Ny4wMDAwMDAsIC01MC4xMDcxNDMpICIgcG9pbnRzPSI4NyA5OS40NzYxNzY4IDAuMzk3NDU5NjIyIDk5LjY2MDcxNDMgNDMuODU5OTgzNyAyNS40MjI2MjU5IDg3IC00OSAxMzAuMTQwMDE2IDI1LjQyMjYyNTkgMTczLjYwMjU0IDk5LjY2MDcxNDMiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTc0cHgiIGhlaWdodD0iMTUwcHgiIHZpZXdCb3g9IjAgMCAxNzQgMTUwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+dHJpYW5nbGUtYm90dG9tPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBvbHlnb24gaWQ9InRyaWFuZ2xlLWJvdHRvbSIgZmlsbD0iIzAwMDAwMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoODcuMDAwMDAwLCA1MC4xMDcxNDMpIHJvdGF0ZSgtMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC04Ny4wMDAwMDAsIC01MC4xMDcxNDMpICIgcG9pbnRzPSI4NyA5OS40NzYxNzY4IDAuMzk3NDU5NjIyIDk5LjY2MDcxNDMgNDMuODU5OTgzNyAyNS40MjI2MjU5IDg3IC00OSAxMzAuMTQwMDE2IDI1LjQyMjYyNTkgMTczLjYwMjU0IDk5LjY2MDcxNDMiPjwvcG9seWdvbj4KICAgIDwvZz4KPC9zdmc+");
}

.mask-triangle-3 {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTUwcHgiIGhlaWdodD0iMTc0cHgiIHZpZXdCb3g9IjAgMCAxNTAgMTc0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+dHJpYW5nbGUtbGVmdDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwb2x5Z29uIGlkPSJ0cmlhbmdsZS1sZWZ0IiBmaWxsPSIjMDAwMDAwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDAuMDAwMDAwLCA4Ny4xMDcxNDMpIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTEwMC4wMDAwMDAsIC04Ny4xMDcxNDMpICIgcG9pbnRzPSIxMDAgMTM2LjQ3NjE3NyAxMy4zOTc0NTk2IDEzNi42NjA3MTQgNTYuODU5OTgzNyA2Mi40MjI2MjU5IDEwMCAtMTIgMTQzLjE0MDAxNiA2Mi40MjI2MjU5IDE4Ni42MDI1NCAxMzYuNjYwNzE0Ij48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTUwcHgiIGhlaWdodD0iMTc0cHgiIHZpZXdCb3g9IjAgMCAxNTAgMTc0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+dHJpYW5nbGUtbGVmdDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwb2x5Z29uIGlkPSJ0cmlhbmdsZS1sZWZ0IiBmaWxsPSIjMDAwMDAwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMDAuMDAwMDAwLCA4Ny4xMDcxNDMpIHJvdGF0ZSgtOTAuMDAwMDAwKSB0cmFuc2xhdGUoLTEwMC4wMDAwMDAsIC04Ny4xMDcxNDMpICIgcG9pbnRzPSIxMDAgMTM2LjQ3NjE3NyAxMy4zOTc0NTk2IDEzNi42NjA3MTQgNTYuODU5OTgzNyA2Mi40MjI2MjU5IDEwMCAtMTIgMTQzLjE0MDAxNiA2Mi40MjI2MjU5IDE4Ni42MDI1NCAxMzYuNjYwNzE0Ij48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
}

.mask-triangle-4 {
  -webkit-mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTUwcHgiIGhlaWdodD0iMTc0cHgiIHZpZXdCb3g9IjAgMCAxNTAgMTc0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+dHJpYW5nbGUtcmlnaHQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cG9seWdvbiBpZD0idHJpYW5nbGUtcmlnaHQiIGZpbGw9IiMwMDAwMDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwLjAwMDAwMCwgODcuMTA3MTQzKSByb3RhdGUoLTI3MC4wMDAwMDApIHRyYW5zbGF0ZSgtNTAuMDAwMDAwLCAtODcuMTA3MTQzKSAiIHBvaW50cz0iNTAgMTM2LjQ3NjE3NyAtMzYuNjAyNTQwNCAxMzYuNjYwNzE0IDYuODU5OTgzNzQgNjIuNDIyNjI1OSA1MCAtMTIgOTMuMTQwMDE2MyA2Mi40MjI2MjU5IDEzNi42MDI1NCAxMzYuNjYwNzE0Ij48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
  mask-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTUwcHgiIGhlaWdodD0iMTc0cHgiIHZpZXdCb3g9IjAgMCAxNTAgMTc0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+dHJpYW5nbGUtcmlnaHQ8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8cG9seWdvbiBpZD0idHJpYW5nbGUtcmlnaHQiIGZpbGw9IiMwMDAwMDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwLjAwMDAwMCwgODcuMTA3MTQzKSByb3RhdGUoLTI3MC4wMDAwMDApIHRyYW5zbGF0ZSgtNTAuMDAwMDAwLCAtODcuMTA3MTQzKSAiIHBvaW50cz0iNTAgMTM2LjQ3NjE3NyAtMzYuNjAyNTQwNCAxMzYuNjYwNzE0IDYuODU5OTgzNzQgNjIuNDIyNjI1OSA1MCAtMTIgOTMuMTQwMDE2MyA2Mi40MjI2MjU5IDEzNi42MDI1NCAxMzYuNjYwNzE0Ij48L3BvbHlnb24+CiAgICA8L2c+Cjwvc3ZnPg==");
}

.menu.horizontal li.bordered > a, .menu.horizontal li.bordered > button, .menu.horizontal li.bordered > span {
  --tw-border-opacity: 1;
  border-bottom-width: 4px;
  border-left-width: 0;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
}

.menu[class*=" p-"] li > *, .menu[class^="p-"] li > * {
  border-radius: var(--rounded-btn, .5rem);
}

.menu :where(li.bordered > *) {
  --tw-border-opacity: 1;
  border-left-width: 4px;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
}

.menu :where(li) > :where(:not(ul)) {
  color: currentColor;
  gap: .75rem;
  padding: .75rem 1rem;
}

.menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul):focus), .menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul):hover) {
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  --tw-bg-opacity: .1;
}

.menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul).active), .menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul):active) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.menu :where(li:empty) {
  height: 1px;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  --tw-bg-opacity: .1;
  margin: .5rem 1rem;
}

.menu li.disabled > * {
  user-select: none;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  --tw-text-opacity: .2;
}

.menu li.disabled > :hover {
  background-color: #0000;
}

.menu li.hover-bordered a {
  border-color: #0000;
  border-left-width: 4px;
}

.menu li.hover-bordered a:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
}

.menu.compact li > a, .menu.compact li > span {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.menu .menu-title > * {
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  --tw-text-opacity: .4;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
}

.menu :where(li:not(.disabled)) > :where(:not(ul)) {
  outline-offset: 2px;
  outline: 2px solid #0000;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.menu > :where(li:first-child) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.menu > :where(li:first-child) > :where(:not(ul)) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.menu > :where(li:last-child) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.menu > :where(li:last-child) > :where(:not(ul)) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.menu > :where(li) > :where(ul) :where(li) {
  width: 100%;
  white-space: nowrap;
}

.menu > :where(li) > :where(ul) :where(li) :where(ul) {
  padding-left: 1rem;
}

.menu > :where(li) > :where(ul) :where(li) > :where(:not(ul)) {
  width: 100%;
  white-space: nowrap;
}

.menu > :where(li) > :where(ul) > :where(li:first-child) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.menu > :where(li) > :where(ul) > :where(li:first-child) > :where(:not(ul)) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.menu > :where(li) > :where(ul) > :where(li:last-child) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.menu > :where(li) > :where(ul) > :where(li:last-child) > :where(:not(ul)) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.mockup-code:before {
  content: "";
  height: .75rem;
  width: .75rem;
  opacity: .3;
  border-radius: 9999px;
  margin-bottom: 1rem;
  display: block;
  box-shadow: 1.4em 0, 2.8em 0, 4.2em 0;
}

.mockup-code pre {
  padding-right: 1.25rem;
}

.mockup-code pre:before {
  content: "";
  margin-right: 2ch;
}

.mockup-window:before {
  content: "";
  height: .75rem;
  width: .75rem;
  opacity: .3;
  border-radius: 9999px;
  margin-bottom: 1rem;
  display: block;
  box-shadow: 1.4em 0, 2.8em 0, 4.2em 0;
}

.mockup-phone {
  background-color: #000;
  border: 4px solid #444;
  border-radius: 50px;
  margin: 0 auto;
  padding: 10px;
  display: inline-block;
  overflow: hidden;
}

.mockup-phone .camera {
  height: 25px;
  width: 150px;
  z-index: 11;
  background: #000;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  margin: 0 auto;
  position: relative;
  top: 0;
  left: 0;
}

.mockup-phone .camera:before {
  content: "";
  width: 50px;
  height: 4px;
  background-color: #0c0b0e;
  border-radius: 5px;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mockup-phone .camera:after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #0f0b25;
  border-radius: 5px;
  position: absolute;
  top: 20%;
  left: 70%;
}

.mockup-phone .display {
  border-radius: 40px;
  margin-top: -25px;
  overflow: hidden;
}

.modal-open .modal-box, .modal-toggle:checked + .modal .modal-box, .modal:target .modal-box {
  --tw-translate-y: 0px;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.modal-action > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.progress::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
}

.progress:indeterminate:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
  content: "";
  width: 33.3333%;
  border-radius: var(--rounded-box, 1rem);
  animation: progress-loading 5s ease-in-out infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -40%;
}

.progress::-webkit-progress-bar {
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
  --tw-bg-opacity: .2;
  border-radius: var(--rounded-box, 1rem);
}

.progress::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity) );
  border-radius: var(--rounded-box, 1rem);
}

@keyframes progress-loading {
  50% {
    left: 107%;
  }
}

.radio:focus-visible {
  outline: 2px solid hsl(var(--bc) );
  outline-offset: 2px;
}

.radio:checked {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  animation: radiomark var(--animation-input, .2s) ease-in-out;
  box-shadow: 0 0 0 4px hsl(var(--b1) ) inset, 0 0 0 4px hsl(var(--b1) ) inset;
}

.radio-primary {
  --chkbg: var(--p);
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
}

.radio-primary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
}

.radio-primary:focus-visible {
  outline: 2px solid hsl(var(--p) );
}

.radio-primary:checked {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.radio-secondary {
  --chkbg: var(--s);
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
}

.radio-secondary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
}

.radio-secondary:focus-visible {
  outline: 2px solid hsl(var(--s) );
}

.radio-secondary:checked {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity) );
}

.radio-accent {
  --chkbg: var(--a);
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
}

.radio-accent:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
}

.radio-accent:focus-visible {
  outline: 2px solid hsl(var(--a) );
}

.radio-accent:checked {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity) );
}

.radio:disabled {
  cursor: not-allowed;
  opacity: .2;
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px hsl(var(--b1) ) inset, 0 0 0 12px hsl(var(--b1) ) inset;
  }

  50% {
    box-shadow: 0 0 0 3px hsl(var(--b1) ) inset, 0 0 0 3px hsl(var(--b1) ) inset;
  }

  100% {
    box-shadow: 0 0 0 4px hsl(var(--b1) ) inset, 0 0 0 4px hsl(var(--b1) ) inset;
  }
}

.radio-mark {
  display: none;
}

.range:focus-visible::-webkit-slider-thumb {
  --focus-shadow: 0 0 0 6px hsl(var(--b1) ) inset, 0 0 0 2rem hsl(var(--range-shdw) ) inset;
}

.range:focus-visible::-moz-range-thumb {
  --focus-shadow: 0 0 0 6px hsl(var(--b1) ) inset, 0 0 0 2rem hsl(var(--range-shdw) ) inset;
}

.range::-webkit-slider-runnable-track {
  height: .5rem;
  width: 100%;
  border-radius: var(--rounded-box, 1rem);
  background-color: hsla(var(--bc) / .1);
}

.range::-moz-range-track {
  height: .5rem;
  width: 100%;
  border-radius: var(--rounded-box, 1rem);
  background-color: hsla(var(--bc) / .1);
}

.range::-webkit-slider-thumb {
  background-color: hsl(var(--b1) );
  height: 1.5rem;
  width: 1.5rem;
  border-radius: var(--rounded-box, 1rem);
  -webkit-appearance: none;
  color: hsl(var(--range-shdw) );
  --filler-size: 100rem;
  --filler-offset: .6rem;
  box-shadow: 0 0 0 3px hsl(var(--range-shdw) ) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset) ) 0 0 var(--filler-size);
  border-style: none;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.range::-moz-range-thumb {
  background-color: hsl(var(--b1) );
  height: 1.5rem;
  width: 1.5rem;
  border-radius: var(--rounded-box, 1rem);
  color: hsl(var(--range-shdw) );
  --filler-size: 100rem;
  --filler-offset: .5rem;
  box-shadow: 0 0 0 3px hsl(var(--range-shdw) ) inset, var(--focus-shadow, 0 0), calc(var(--filler-size) * -1 - var(--filler-offset) ) 0 0 var(--filler-size);
  border-style: none;
  position: relative;
  top: 50%;
}

.range-primary {
  --range-shdw: var(--p);
}

.range-secondary {
  --range-shdw: var(--s);
}

.range-accent {
  --range-shdw: var(--a);
}

.rating input {
  appearance: none;
}

.rating .rating-hidden {
  width: .5rem;
  background-color: #0000;
}

.rating input:checked ~ input {
  --tw-bg-opacity: .2;
}

.rating input:focus-visible {
  transition-property: transform;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transform: translateY(-.125em);
}

.rating input:active:focus {
  animation: none;
  transform: translateY(-.125em);
}

.rating-half :where(input:not(.rating-hidden)) {
  width: .75rem;
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

.select-bordered {
  --tw-border-opacity: .2;
}

.select:focus {
  outline: 2px solid hsla(var(--bc) / .2);
  outline-offset: 2px;
}

.select-ghost {
  --tw-bg-opacity: .05;
}

.select-ghost:focus {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
}

.select-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
}

.select-primary:focus {
  outline: 2px solid hsl(var(--p) );
}

.select-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
}

.select-secondary:focus {
  outline: 2px solid hsl(var(--s) );
}

.select-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
}

.select-accent:focus {
  outline: 2px solid hsl(var(--a) );
}

.select-info {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity) );
}

.select-info:focus {
  outline: 2px solid hsl(var(--in) );
}

.select-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity) );
}

.select-success:focus {
  outline: 2px solid hsl(var(--su) );
}

.select-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity) );
}

.select-warning:focus {
  outline: 2px solid hsl(var(--wa) );
}

.select-error {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity) );
}

.select-error:focus {
  outline: 2px solid hsl(var(--er) );
}

.select-disabled, .select[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
  --tw-text-opacity: .2;
}

.select-disabled::placeholder, .select[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity) );
  --tw-placeholder-opacity: .2;
}

.select-multiple, .select[multiple], .select[size].select:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}

:where(.stats) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse) );
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse) ) );
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse) );
}

.steps .step:before {
  height: .5rem;
  width: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  content: "";
  grid-row-start: 1;
  grid-column-start: 1;
  margin-left: -100%;
  top: 0;
}

.steps .step:after {
  content: counter(step);
  counter-increment: step;
  z-index: 1;
  height: 2rem;
  width: 2rem;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  border-radius: 9999px;
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: center;
  place-items: center;
  display: grid;
  position: relative;
}

.steps .step:first-child:before {
  content: none;
}

.steps .step[data-content]:after {
  content: attr(data-content);
}

.steps .step-neutral + .step-neutral:before, .steps .step-neutral:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity) );
}

.steps .step-primary + .step-primary:before, .steps .step-primary:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.steps .step-secondary + .step-secondary:before, .steps .step-secondary:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity) );
}

.steps .step-accent + .step-accent:before, .steps .step-accent:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity) );
}

.steps .step-info + .step-info:before {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity) );
}

.steps .step-info:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--inc, var(--nc)) / var(--tw-text-opacity) );
}

.steps .step-success + .step-success:before {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity) );
}

.steps .step-success:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--suc, var(--nc)) / var(--tw-text-opacity) );
}

.steps .step-warning + .step-warning:before {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity) );
}

.steps .step-warning:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--wac, var(--nc)) / var(--tw-text-opacity) );
}

.steps .step-error + .step-error:before {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity) );
}

.steps .step-error:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--erc, var(--nc)) / var(--tw-text-opacity) );
}

.swap-rotate .swap-on, .swap-rotate .swap-indeterminate, .swap-rotate input:indeterminate ~ .swap-on {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.swap-rotate input:checked ~ .swap-off, .swap-rotate.swap-active .swap-off, .swap-rotate input:indeterminate ~ .swap-off {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.swap-rotate input:checked ~ .swap-on, .swap-rotate.swap-active .swap-on, .swap-rotate input:indeterminate ~ .swap-indeterminate {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.swap-flip {
  transform-style: preserve-3d;
  perspective: 16em;
}

.swap-flip .swap-on, .swap-flip .swap-indeterminate, .swap-flip input:indeterminate ~ .swap-on {
  backface-visibility: hidden;
  opacity: 1;
  transform: rotateY(180deg);
}

.swap-flip input:checked ~ .swap-off, .swap-flip.swap-active .swap-off, .swap-flip input:indeterminate ~ .swap-off {
  backface-visibility: hidden;
  opacity: 1;
  transform: rotateY(-180deg);
}

.swap-flip input:checked ~ .swap-on, .swap-flip.swap-active .swap-on, .swap-flip input:indeterminate ~ .swap-indeterminate {
  transform: rotateY(0deg);
}

.tab:hover {
  --tw-text-opacity: 1;
}

.tab.tab-active {
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
}

.tab:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.tab:focus-visible {
  outline-offset: -3px;
  outline: 2px solid;
}

.tab:focus-visible.tab-lifted {
  border-bottom-right-radius: var(--tab-radius, .5rem);
  border-bottom-left-radius: var(--tab-radius, .5rem);
}

.tab-bordered {
  border-color: hsl(var(--bc) / var(--tw-border-opacity) );
  --tw-border-opacity: .2;
  border-style: solid;
  border-bottom-width: calc(var(--tab-border, 1px)  + 1px);
}

.tab-lifted {
  border: var(--tab-border, 1px) solid transparent;
  border-width: 0 0 var(--tab-border, 1px) 0;
  border-top-left-radius: var(--tab-radius, .5rem);
  border-top-right-radius: var(--tab-radius, .5rem);
  border-bottom-color: var(--tab-border-color);
  padding-left: var(--tab-padding, 1rem);
  padding-right: var(--tab-padding, 1rem);
  padding-top: var(--tab-border, 1px);
}

.tab-lifted.tab-active {
  background-color: var(--tab-bg);
  border-width: var(--tab-border, 1px) var(--tab-border, 1px) 0 var(--tab-border, 1px);
  border-left-color: var(--tab-border-color);
  border-right-color: var(--tab-border-color);
  border-top-color: var(--tab-border-color);
  padding-left: calc(var(--tab-padding, 1rem)  - var(--tab-border, 1px) );
  padding-right: calc(var(--tab-padding, 1rem)  - var(--tab-border, 1px) );
  padding-bottom: var(--tab-border, 1px);
  padding-top: 0;
}

.tab-lifted.tab-active:before, .tab-lifted.tab-active:after {
  z-index: 1;
  content: "";
  width: var(--tab-radius, .5rem);
  height: var(--tab-radius, .5rem);
  --tab-grad: calc(68% - var(--tab-border, 1px) );
  --tab-corner-bg: radial-gradient(circle at var(--circle-pos), transparent var(--tab-grad), var(--tab-border-color) calc(var(--tab-grad)  + .3px), var(--tab-border-color) calc(var(--tab-grad)  + var(--tab-border, 1px) ), var(--tab-bg) calc(var(--tab-grad)  + var(--tab-border, 1px)  + .3px) );
  display: block;
  position: absolute;
  bottom: 0;
}

.tab-lifted.tab-active:before {
  left: calc(var(--tab-radius, .5rem) * -1);
  --circle-pos: top left;
  background-image: var(--tab-corner-bg);
}

[dir="rtl"] .tab-lifted.tab-active:before {
  --circle-pos: top right;
}

.tab-lifted.tab-active:after {
  right: calc(var(--tab-radius, .5rem) * -1);
  --circle-pos: top right;
  background-image: var(--tab-corner-bg);
}

[dir="rtl"] .tab-lifted.tab-active:after {
  --circle-pos: top left;
}

.tab-lifted.tab-active:first-child:before, .tab-lifted.tab-active:last-child:after, .tab-lifted.tab-active + .tab-lifted.tab-active:before {
  background: none;
}

.tabs-boxed {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
  border-radius: var(--rounded-btn, .5rem);
  padding: .25rem;
}

.tabs-boxed .tab-active {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
  border-radius: var(--rounded-btn, .5rem);
}

.tabs-boxed .tab-active:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.table :where(th, td) {
  white-space: nowrap;
  vertical-align: middle;
  padding: 1rem;
}

.table tr.active th, .table tr.active td, .table tr.active:nth-child(2n) th, .table tr.active:nth-child(2n) td, .table tr.hover:hover th, .table tr.hover:hover td, .table tr.hover:nth-child(2n):hover th, .table tr.hover:nth-child(2n):hover td {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) );
}

.table:where(:not(.table-zebra)) :where(thead, tbody, tfoot) :where(tr:not(:last-child) :where(th, td)) {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity) );
}

.table :where(thead, tfoot) :where(th, td) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
}

.table :where(:first-child) :where(:first-child) :where(th, td):first-child {
  border-top-left-radius: .5rem;
}

.table :where(:first-child) :where(:first-child) :where(th, td):last-child {
  border-top-right-radius: .5rem;
}

.table :where(:last-child) :where(:last-child) :where(th, td):first-child {
  border-bottom-left-radius: .5rem;
}

.table :where(:last-child) :where(:last-child) :where(th, td):last-child {
  border-bottom-right-radius: .5rem;
}

.table :where(tbody th, tbody td) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
}

.table-zebra tbody tr:nth-child(2n) th, .table-zebra tbody tr:nth-child(2n) td {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
}

.textarea-bordered {
  --tw-border-opacity: .2;
}

.textarea:focus {
  outline: 2px solid hsla(var(--bc) / .2);
  outline-offset: 2px;
}

.textarea-ghost {
  --tw-bg-opacity: .05;
}

.textarea-ghost:focus {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  box-shadow: none;
}

.textarea-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
}

.textarea-primary:focus {
  outline: 2px solid hsl(var(--p) );
}

.textarea-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
}

.textarea-secondary:focus {
  outline: 2px solid hsl(var(--s) );
}

.textarea-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
}

.textarea-accent:focus {
  outline: 2px solid hsl(var(--a) );
}

.textarea-info {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity) );
}

.textarea-info:focus {
  outline: 2px solid hsl(var(--in) );
}

.textarea-success {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity) );
}

.textarea-success:focus {
  outline: 2px solid hsl(var(--su) );
}

.textarea-warning {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity) );
}

.textarea-warning:focus {
  outline: 2px solid hsl(var(--wa) );
}

.textarea-error {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity) );
}

.textarea-error:focus {
  outline: 2px solid hsl(var(--er) );
}

.textarea-disabled, .textarea[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
  --tw-text-opacity: .2;
}

.textarea-disabled::placeholder, .textarea[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity) );
  --tw-placeholder-opacity: .2;
}

.toast > * {
  animation: toast-pop .25s ease-out;
}

@keyframes toast-pop {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.toggle:focus-visible {
  outline: 2px solid hsl(var(--bc) );
  outline-offset: 2px;
}

.toggle:checked, .toggle[checked="true"] {
  --chkbg: hsl(var(--bc) );
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  box-shadow: var(--handleoffset) 0 0 2px hsl(var(--b1) ) inset, 0 0 0 2px hsl(var(--b1) ) inset;
}

[dir="rtl"] .toggle:checked, [dir="rtl"] .toggle[checked="true"] {
  box-shadow: calc(var(--handleoffset) * 1) 0 0 2px hsl(var(--b1) ) inset, 0 0 0 2px hsl(var(--b1) ) inset;
}

.toggle:indeterminate {
  --chkbg: hsl(var(--bc) );
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px hsl(var(--b1) ) inset, calc(var(--handleoffset) / -2) 0 0 2px hsl(var(--b1) ) inset, 0 0 0 2px hsl(var(--b1) ) inset;
}

[dir="rtl"] .toggle:indeterminate {
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px hsl(var(--b1) ) inset, calc(var(--handleoffset) / -2) 0 0 2px hsl(var(--b1) ) inset, 0 0 0 2px hsl(var(--b1) ) inset;
}

.toggle-primary:focus-visible {
  outline: 2px solid hsl(var(--p) );
}

.toggle-primary:checked, .toggle-primary[checked="true"] {
  --chkbg: hsl(var(--p) );
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.toggle-secondary:focus-visible {
  outline: 2px solid hsl(var(--s) );
}

.toggle-secondary:checked, .toggle-secondary[checked="true"] {
  --chkbg: hsl(var(--s) );
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity) );
}

.toggle-accent:focus-visible {
  outline: 2px solid hsl(var(--a) );
}

.toggle-accent:checked, .toggle-accent[checked="true"] {
  --chkbg: hsl(var(--a) );
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity) );
}

.toggle:disabled {
  cursor: not-allowed;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity) );
  --tw-bg-opacity: .2;
  border-color: #0000;
}

.toggle-mark {
  display: none;
}

.tooltip:before, .tooltip:after {
  opacity: 0;
  transition: color .2s cubic-bezier(.4, 0, .2, 1) .1s, background-color .2s cubic-bezier(.4, 0, .2, 1) .1s, border-color .2s cubic-bezier(.4, 0, .2, 1) .1s, text-decoration-color .2s cubic-bezier(.4, 0, .2, 1) .1s, fill .2s cubic-bezier(.4, 0, .2, 1) .1s, stroke .2s cubic-bezier(.4, 0, .2, 1) .1s, opacity .2s cubic-bezier(.4, 0, .2, 1) .1s, box-shadow .2s cubic-bezier(.4, 0, .2, 1) .1s, transform .2s cubic-bezier(.4, 0, .2, 1) .1s, filter .2s cubic-bezier(.4, 0, .2, 1) .1s, backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s, -webkit-text-decoration-color .2s cubic-bezier(.4, 0, .2, 1) .1s, -webkit-backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s;
}

.tooltip:after {
  content: "";
  border-style: solid;
  border-width: var(--tooltip-tail, 0);
  width: 0;
  height: 0;
  border-color: var(--tooltip-color) transparent transparent transparent;
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-tail-offset);
  display: block;
  position: absolute;
  transform: translateX(-50%);
}

.tooltip.tooltip-open:before, .tooltip.tooltip-open:after, .tooltip:hover:before, .tooltip:hover:after {
  opacity: 1;
  transition-delay: 75ms;
}

.tooltip-primary {
  --tooltip-color: hsl(var(--p) );
  --tooltip-text-color: hsl(var(--pc) );
}

.tooltip-secondary {
  --tooltip-color: hsl(var(--s) );
  --tooltip-text-color: hsl(var(--sc) );
}

.tooltip-accent {
  --tooltip-color: hsl(var(--a) );
  --tooltip-text-color: hsl(var(--ac) );
}

.tooltip-info {
  --tooltip-color: hsla(var(--in) );
  --tooltip-text-color: hsl(var(--inc) );
}

.tooltip-success {
  --tooltip-color: hsla(var(--su) );
  --tooltip-text-color: hsl(var(--suc) );
}

.tooltip-warning {
  --tooltip-color: hsla(var(--wa) );
  --tooltip-text-color: hsl(var(--wac) );
}

.tooltip-error {
  --tooltip-color: hsla(var(--er) );
  --tooltip-text-color: hsl(var(--erc) );
}

:root .prose {
  --tw-prose-body: hsla(var(--bc) / .8);
  --tw-prose-headings: hsl(var(--bc) );
  --tw-prose-lead: hsl(var(--bc) );
  --tw-prose-links: hsl(var(--bc) );
  --tw-prose-bold: hsl(var(--bc) );
  --tw-prose-counters: hsl(var(--bc) );
  --tw-prose-bullets: hsla(var(--bc) / .5);
  --tw-prose-hr: hsla(var(--bc) / .2);
  --tw-prose-quotes: hsl(var(--bc) );
  --tw-prose-quote-borders: hsla(var(--bc) / .2);
  --tw-prose-captions: hsla(var(--bc) / .5);
  --tw-prose-code: hsl(var(--bc) );
  --tw-prose-pre-code: hsl(var(--nc) );
  --tw-prose-pre-bg: hsl(var(--n) );
  --tw-prose-th-borders: hsla(var(--bc) / .5);
  --tw-prose-td-borders: hsla(var(--bc) / .2);
}

.prose :where(code):not(:where([class~="not-prose"] *)) {
  border-radius: var(--rounded-badge);
  padding: 2px 8px;
}

.prose code:after, .prose code:before {
  content: none;
}

.prose pre code {
  border-radius: 0;
  padding: 0;
}

.prose :where(tbody tr, thead):not(:where([class~="not-prose"] *)) {
  border-bottom-color: hsl(var(--bc) / 20%);
}

.rounded-box {
  border-radius: var(--rounded-box, 1rem);
}

.rounded-t-box {
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
}

.rounded-b-box {
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
}

.rounded-l-box {
  border-top-left-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
}

.rounded-r-box {
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
}

.rounded-br-box {
  border-bottom-right-radius: var(--rounded-box, 1rem);
}

.rounded-tr-box {
  border-top-right-radius: var(--rounded-box, 1rem);
}

.rounded-bl-box {
  border-bottom-left-radius: var(--rounded-box, 1rem);
}

.rounded-tl-box {
  border-top-left-radius: var(--rounded-box, 1rem);
}

.rounded-btn {
  border-radius: var(--rounded-btn, .5rem);
}

.rounded-badge {
  border-radius: var(--rounded-badge, 1.9rem);
}

.text-2xs {
  font-size: .7rem;
}

.glass, .glass:hover, .glass.btn-active {
  -webkit-backdrop-filter: blur(var(--glass-blur, 40px) );
  backdrop-filter: blur(var(--glass-blur, 40px) );
  background-color: #0000;
  background-image: linear-gradient( 135deg, rgb(255 255 255 / var(--glass-opacity, 30%) ) 0%, #0000 100% ), linear-gradient( var(--glass-reflex-degree, 100deg), rgb(255 255 255 / var(--glass-reflex-opacity, 10%) ) 25%, #0000 25% );
  box-shadow: 0 0 0 1px rgb(255 255 255 / var(--glass-border-opacity, 10%) ) inset, 0 0 0 2px #0000000d;
  text-shadow: 0 1px rgb(0 0 0 / var(--glass-text-shadow-opacity, 5%) );
  border: none;
}

.min-h-6 {
  min-height: 1.5rem;
}

.min-h-8 {
  min-height: 2rem;
}

.min-h-12 {
  min-height: 3rem;
}

.min-h-16 {
  min-height: 4rem;
}

.no-animation {
  --btn-focus-scale: 1;
  --animation-btn: 0;
  --animation-input: 0;
}

.tab-border-none {
  --tab-border: 0px;
}

.tab-border {
  --tab-border: 1px;
}

.tab-border-2 {
  --tab-border: 2px;
}

.tab-border-3 {
  --tab-border: 3px;
}

.tab-rounded-none {
  --tab-radius: 0;
}

.tab-rounded-lg {
  --tab-radius: .5rem;
}

.artboard-demo {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

.artboard.phone {
  width: 320px;
}

.artboard.phone-1 {
  width: 320px;
  height: 568px;
}

.artboard.phone-1.horizontal, .artboard.phone-1.artboard-horizontal {
  width: 568px;
  height: 320px;
}

.artboard.phone-2 {
  width: 375px;
  height: 667px;
}

.artboard.phone-2.horizontal, .artboard.phone-2.artboard-horizontal {
  width: 667px;
  height: 375px;
}

.artboard.phone-3 {
  width: 414px;
  height: 736px;
}

.artboard.phone-3.horizontal, .artboard.phone-3.artboard-horizontal {
  width: 736px;
  height: 414px;
}

.artboard.phone-4 {
  width: 375px;
  height: 812px;
}

.artboard.phone-4.horizontal, .artboard.phone-4.artboard-horizontal {
  width: 812px;
  height: 375px;
}

.artboard.phone-5 {
  width: 414px;
  height: 896px;
}

.artboard.phone-5.horizontal, .artboard.phone-5.artboard-horizontal {
  width: 896px;
  height: 414px;
}

.artboard.phone-6 {
  width: 320px;
  height: 1024px;
}

.artboard.phone-6.horizontal, .artboard.phone-6.artboard-horizontal {
  width: 1024px;
  height: 320px;
}

.badge-xs {
  height: .75rem;
  padding-left: .313rem;
  padding-right: .313rem;
  font-size: .75rem;
  line-height: .75rem;
}

.badge-sm {
  height: 1rem;
  padding-left: .438rem;
  padding-right: .438rem;
  font-size: .75rem;
  line-height: 1rem;
}

.badge-md {
  height: 1.25rem;
  padding-left: .563rem;
  padding-right: .563rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.badge-lg {
  height: 1.5rem;
  padding-left: .688rem;
  padding-right: .688rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.btm-nav-xs {
  height: 2.5rem;
}

.btm-nav-xs > :where(.active) {
  border-top-width: 1px;
}

.btm-nav-xs .btm-nav-label {
  font-size: .75rem;
  line-height: 1rem;
}

.btm-nav-sm {
  height: 3rem;
}

.btm-nav-sm > :where(.active) {
  border-top-width: 2px;
}

.btm-nav-sm .btm-nav-label {
  font-size: .75rem;
  line-height: 1rem;
}

.btm-nav-md {
  height: 4rem;
}

.btm-nav-md > :where(.active) {
  border-top-width: 2px;
}

.btm-nav-md .btm-nav-label {
  font-size: .875rem;
  line-height: 1.25rem;
}

.btm-nav-lg {
  height: 5rem;
}

.btm-nav-lg > :where(.active) {
  border-top-width: 4px;
}

.btm-nav-lg .btm-nav-label {
  font-size: 1rem;
  line-height: 1.5rem;
}

.btn-xs {
  height: 1.5rem;
  min-height: 1.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .75rem;
}

.btn-sm {
  height: 2rem;
  min-height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
}

.btn-md {
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
}

.btn-lg {
  height: 4rem;
  min-height: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1.125rem;
}

.btn-wide {
  width: 16rem;
}

.btn-block {
  width: 100%;
}

.btn-square:where(.btn-xs) {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
}

.btn-square:where(.btn-sm) {
  height: 2rem;
  width: 2rem;
  padding: 0;
}

.btn-square:where(.btn-md) {
  height: 3rem;
  width: 3rem;
  padding: 0;
}

.btn-square:where(.btn-lg) {
  height: 4rem;
  width: 4rem;
  padding: 0;
}

.btn-circle:where(.btn-xs) {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 9999px;
  padding: 0;
}

.btn-circle:where(.btn-sm) {
  height: 2rem;
  width: 2rem;
  border-radius: 9999px;
  padding: 0;
}

.btn-circle:where(.btn-md) {
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  padding: 0;
}

.btn-circle:where(.btn-lg) {
  height: 4rem;
  width: 4rem;
  border-radius: 9999px;
  padding: 0;
}

.card-side {
  flex-direction: row;
  align-items: stretch;
}

.card-side figure > * {
  max-width: unset;
}

:where(.card-side figure > *) {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.checkbox-xs {
  height: 1rem;
  width: 1rem;
}

.checkbox-sm {
  height: 1.25rem;
  width: 1.25rem;
}

.checkbox-md {
  height: 1.5rem;
  width: 1.5rem;
}

.checkbox-lg {
  height: 2rem;
  width: 2rem;
}

.divider-horizontal {
  flex-direction: column;
}

.divider-horizontal:before, .divider-horizontal:after {
  height: 100%;
  width: .125rem;
}

.divider-vertical {
  flex-direction: row;
}

.divider-vertical:before, .divider-vertical:after {
  height: .125rem;
  width: 100%;
}

.indicator :where(.indicator-item) {
  --tw-translate-x: 50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  inset: 0 0 auto auto;
}

.indicator :where(.indicator-item.indicator-start) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  left: 0;
  right: auto;
}

.indicator :where(.indicator-item.indicator-center) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  left: 50%;
  right: 50%;
}

.indicator :where(.indicator-item.indicator-end) {
  --tw-translate-x: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  left: auto;
  right: 0;
}

.indicator :where(.indicator-item.indicator-bottom) {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  top: auto;
  bottom: 0;
}

.indicator :where(.indicator-item.indicator-middle) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  top: 50%;
  bottom: 50%;
}

.indicator :where(.indicator-item.indicator-top) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  top: 0;
  bottom: auto;
}

.input-md {
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  line-height: 2;
}

.input-lg {
  height: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1.125rem;
  line-height: 2;
}

.input-sm {
  height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
  line-height: 2rem;
}

.input-xs {
  height: 1.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .75rem;
  line-height: 1.625;
}

.kbd-xs {
  min-height: 1.2em;
  min-width: 1.2em;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .75rem;
  line-height: 1rem;
}

.kbd-sm {
  min-height: 1.6em;
  min-width: 1.6em;
  padding-left: .25rem;
  padding-right: .25rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.kbd-md {
  min-height: 2.2em;
  min-width: 2.2em;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.kbd-lg {
  min-height: 2.5em;
  min-width: 2.5em;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.menu-vertical {
  flex-direction: column;
}

.menu-vertical :where(li) {
  flex-direction: column;
}

.menu-vertical > :where(li) > :where(ul) {
  top: initial;
  left: 100%;
}

.menu-horizontal {
  flex-direction: row;
  display: inline-flex;
}

.menu-horizontal :where(li) {
  flex-direction: row;
}

.menu-horizontal > :where(li) > :where(ul) {
  top: 100%;
  left: initial;
}

.modal-bottom {
  align-items: flex-end;
}

.modal-middle {
  align-items: center;
}

.radio-xs {
  height: 1rem;
  width: 1rem;
}

.radio-sm {
  height: 1.25rem;
  width: 1.25rem;
}

.radio-md {
  height: 1.5rem;
  width: 1.5rem;
}

.radio-lg {
  height: 2rem;
  width: 2rem;
}

.range-xs {
  height: 1rem;
}

.range-xs::-webkit-slider-runnable-track {
  height: .25rem;
}

.range-xs::-moz-range-track {
  height: .25rem;
}

.range-xs::-webkit-slider-thumb {
  height: 1rem;
  width: 1rem;
  --filler-offset: .4rem;
}

.range-xs::-moz-range-thumb {
  height: 1rem;
  width: 1rem;
  --filler-offset: .4rem;
}

.range-sm {
  height: 1.25rem;
}

.range-sm::-webkit-slider-runnable-track {
  height: .25rem;
}

.range-sm::-moz-range-track {
  height: .25rem;
}

.range-sm::-webkit-slider-thumb {
  height: 1.25rem;
  width: 1.25rem;
  --filler-offset: .5rem;
}

.range-sm::-moz-range-thumb {
  height: 1.25rem;
  width: 1.25rem;
  --filler-offset: .5rem;
}

.range-md {
  height: 1.5rem;
}

.range-md::-webkit-slider-runnable-track {
  height: .5rem;
}

.range-md::-moz-range-track {
  height: .5rem;
}

.range-md::-webkit-slider-thumb {
  height: 1.5rem;
  width: 1.5rem;
  --filler-offset: .6rem;
}

.range-md::-moz-range-thumb {
  height: 1.5rem;
  width: 1.5rem;
  --filler-offset: .6rem;
}

.range-lg {
  height: 2rem;
}

.range-lg::-webkit-slider-runnable-track {
  height: 1rem;
}

.range-lg::-moz-range-track {
  height: 1rem;
}

.range-lg::-webkit-slider-thumb {
  height: 2rem;
  width: 2rem;
  --filler-offset: 1rem;
}

.range-lg::-moz-range-thumb {
  height: 2rem;
  width: 2rem;
  --filler-offset: 1rem;
}

.rating-xs input {
  height: .75rem;
  width: .75rem;
}

.rating-sm input {
  height: 1rem;
  width: 1rem;
}

.rating-md input {
  height: 1.5rem;
  width: 1.5rem;
}

.rating-lg input {
  height: 2.5rem;
  width: 2.5rem;
}

.rating-half.rating-xs input:not(.rating-hidden) {
  width: .375rem;
}

.rating-half.rating-sm input:not(.rating-hidden) {
  width: .5rem;
}

.rating-half.rating-md input:not(.rating-hidden) {
  width: .75rem;
}

.rating-half.rating-lg input:not(.rating-hidden) {
  width: 1.25rem;
}

.select-md {
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  font-size: .875rem;
  line-height: 2;
}

.select-lg {
  height: 4rem;
  min-height: 4rem;
  padding-left: 1.5rem;
  padding-right: 2rem;
  font-size: 1.125rem;
  line-height: 2;
}

.select-sm {
  height: 2rem;
  min-height: 2rem;
  padding-left: .75rem;
  padding-right: 2rem;
  font-size: .875rem;
  line-height: 2rem;
}

.select-xs {
  height: 1.5rem;
  min-height: 1.5rem;
  padding-left: .5rem;
  padding-right: 2rem;
  font-size: .75rem;
  line-height: 1.625;
}

.stats-horizontal {
  grid-auto-flow: column;
}

.stats-vertical {
  grid-auto-flow: row;
}

.steps-horizontal {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  display: inline-grid;
  overflow-x: auto;
  overflow-y: hidden;
}

.steps-horizontal .step {
  text-align: center;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  place-items: center;
  display: grid;
}

.steps-vertical {
  grid-auto-rows: 1fr;
  grid-auto-flow: row;
}

.steps-vertical .step {
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

.tab-md {
  height: 2rem;
  --tab-padding: 1rem;
  font-size: .875rem;
  line-height: 2;
}

.tab-lg {
  height: 3rem;
  --tab-padding: 1.25rem;
  font-size: 1.125rem;
  line-height: 2;
}

.tab-sm {
  height: 1.5rem;
  --tab-padding: .75rem;
  font-size: .875rem;
  line-height: .75rem;
}

.tab-xs {
  height: 1.25rem;
  --tab-padding: .5rem;
  font-size: .75rem;
  line-height: .75rem;
}

:where(.toast) {
  --tw-translate-x: 0px;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  inset: auto 0 0 auto;
}

.toast:where(.toast-start) {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  left: 0;
  right: auto;
}

.toast:where(.toast-center) {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  left: 50%;
  right: 50%;
}

.toast:where(.toast-end) {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  left: auto;
  right: 0;
}

.toast:where(.toast-bottom) {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  top: auto;
  bottom: 0;
}

.toast:where(.toast-middle) {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  top: 50%;
  bottom: auto;
}

.toast:where(.toast-top) {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  top: 0;
  bottom: auto;
}

.toggle-xs {
  --handleoffset: .5rem;
  height: 1rem;
  width: 1.5rem;
}

.toggle-sm {
  --handleoffset: .75rem;
  height: 1.25rem;
  width: 2rem;
}

.toggle-md {
  --handleoffset: 1.5rem;
  height: 1.5rem;
  width: 3rem;
}

.toggle-lg {
  --handleoffset: 2rem;
  height: 2rem;
  width: 4rem;
}

.alert-sm {
  padding: .5rem;
}

.alert-info {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--inc, var(--nc)) / var(--tw-text-opacity) );
}

.alert-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--suc, var(--nc)) / var(--tw-text-opacity) );
}

.alert-warning {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--wac, var(--nc)) / var(--tw-text-opacity) );
}

.alert-error {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--erc, var(--nc)) / var(--tw-text-opacity) );
}

.artboard-demo {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
  border-radius: var(--rounded-box, 1rem);
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
}

.avatar.online:before {
  content: "";
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity) );
  width: 15%;
  height: 15%;
  box-shadow: 0 0 0 2px hsl(var(--b1) );
  border-radius: 9999px;
  display: block;
  position: absolute;
  top: 7%;
  right: 7%;
}

.avatar.offline:before {
  content: "";
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) );
  width: 15%;
  height: 15%;
  box-shadow: 0 0 0 2px hsl(var(--b1) );
  border-radius: 9999px;
  display: block;
  position: absolute;
  top: 7%;
  right: 7%;
}

.badge-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.badge-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity) );
}

.badge-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity) );
}

.badge-info {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--inc, var(--nc)) / var(--tw-text-opacity) );
  border-color: #0000;
}

.badge-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--suc, var(--nc)) / var(--tw-text-opacity) );
  border-color: #0000;
}

.badge-warning {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--wac, var(--nc)) / var(--tw-text-opacity) );
  border-color: #0000;
}

.badge-error {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--erc, var(--nc)) / var(--tw-text-opacity) );
  border-color: #0000;
}

.badge-ghost {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
}

.badge-outline {
  --tw-border-opacity: .5;
  color: currentColor;
  background-color: #0000;
  border-color: currentColor;
}

.badge-outline.badge-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity) );
}

.badge-outline.badge-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity) );
}

.badge-outline.badge-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity) );
}

.badge-outline.badge-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity) );
}

.badge-outline.badge-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity) );
}

.badge-outline.badge-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity) );
}

.badge-outline.badge-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity) );
}

.card-compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-compact .card-title {
  margin-bottom: .25rem;
}

.card-normal .card-body {
  padding: var(--padding-card, 2rem);
  font-size: 1rem;
  line-height: 1.5rem;
}

.card-normal .card-title {
  margin-bottom: .75rem;
}

.divider-horizontal {
  height: auto;
  width: 1rem;
  margin: 0 1rem;
}

.divider-vertical {
  height: 1rem;
  width: auto;
  margin: 1rem 0;
}

.menu-vertical :where(li.bordered > *) {
  border-bottom-width: 0;
  border-left-width: 4px;
}

.menu-horizontal :where(li.bordered > *) {
  border-bottom-width: 4px;
  border-left-width: 0;
}

.menu-normal :where(li > *) {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.menu-compact :where(li > *) {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.menu-vertical > :where(li:first-child) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.menu-vertical > :where(li:first-child) > :where(:not(ul)) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.menu-vertical > :where(li:last-child) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.menu-vertical > :where(li:last-child) > :where(:not(ul)) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.menu-horizontal > :where(li:first-child) {
  border-top-left-radius: inherit;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: inherit;
}

.menu-horizontal > :where(li:first-child) > :where(:not(ul)) {
  border-top-left-radius: inherit;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: inherit;
}

.menu-horizontal > :where(li:last-child) {
  border-top-left-radius: unset;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: unset;
}

.menu-horizontal > :where(li:last-child) > :where(:not(ul)) {
  border-top-left-radius: unset;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: unset;
}

.modal-bottom :where(.modal-box) {
  width: 100%;
  max-width: none;
  --tw-translate-y: 2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.modal-middle :where(.modal-box) {
  width: 91.6667%;
  max-width: 32rem;
  --tw-translate-y: 0px;
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
}

.stats-horizontal > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse) );
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse) ) );
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse) );
}

.stats-horizontal {
  overflow-x: auto;
}

.stats-vertical > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse) ) );
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse) );
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse) );
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse) ) );
}

.stats-vertical {
  overflow-y: auto;
}

.steps-horizontal .step {
  min-width: 4rem;
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
}

.steps-horizontal .step:before {
  height: .5rem;
  width: 100%;
  --tw-translate-y: 0px;
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  content: "";
  margin-left: -100%;
}

.steps-vertical .step {
  min-height: 4rem;
  grid-template-rows: auto;
  grid-template-columns: 40px 1fr;
  justify-items: start;
  gap: .5rem;
}

.steps-vertical .step:before {
  height: 100%;
  width: .5rem;
  --tw-translate-y: -50%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  margin-left: 50%;
}

.table-normal :where(th, td) {
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.table-compact :where(th, td) {
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.isolate {
  isolation: isolate;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.z-0 {
  z-index: 0;
}

.m-6 {
  margin: 1.5rem;
}

.m-2 {
  margin: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.-mt-\[30px\] {
  margin-top: -30px;
}

.-ml-\[86px\] {
  margin-left: -86px;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.h-20 {
  height: 5rem;
}

.w-56 {
  width: 14rem;
}

.w-full {
  width: 100%;
}

.w-64 {
  width: 16rem;
}

.w-24 {
  width: 6rem;
}

.min-w-fit {
  min-width: fit-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.flex-1 {
  flex: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-fadein {
  animation: fadein .2s ease-in;
}

@keyframes wiggle {
  0%, 100% {
    transform: translateX(0);
  }

  30%, 70% {
    transform: translateX(-3%);
  }

  10%, 50%, 90% {
    transform: translateX(3%);
  }
}

.animate-wiggle {
  animation: wiggle .3s ease-in-out;
}

.select-none {
  user-select: none;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-8 {
  gap: 2rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.border {
  border-width: 1px;
}

.bg-base-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity) );
}

.bg-neutral {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity) );
}

.bg-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity) );
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity) );
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity) );
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pt-4 {
  padding-top: 1rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.text-center {
  text-align: center;
}

.font-mono {
  font-family: Fira Code, monospace;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.normal-case {
  text-transform: none;
}

.text-base-content {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity) );
}

.text-neutral {
  --tw-text-opacity: 1;
  color: hsl(var(--n) / var(--tw-text-opacity) );
}

.text-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity) );
}

.text-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity) );
}

.text-base-300 {
  --tw-text-opacity: 1;
  color: hsl(var(--b3, var(--b2)) / var(--tw-text-opacity) );
}

.text-success-content {
  --tw-text-opacity: 1;
  color: hsl(var(--suc, var(--nc)) / var(--tw-text-opacity) );
}

.text-primary-content {
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity) );
}

.text-secondary-content {
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity) );
}

.text-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity) );
}

.underline {
  text-decoration-line: underline;
}

.opacity-70 {
  opacity: .7;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-filter {
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

.sm\:bg-logo-alt {
  background-position: right -100px bottom;
  background-repeat: no-repeat;
  background-size: auto 90%;
}

.table tr.hover\:hover:hover:hover th, .table tr.hover\:hover:hover:hover td, .table tr.hover\:hover:hover:nth-child(2n):hover th, .table tr.hover\:hover:hover:nth-child(2n):hover td {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity) );
}

@media (min-width: 640px) {
  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:table-header-group {
    display: table-header-group;
  }

  .sm\:min-h-\[440px\] {
    min-height: 440px;
  }

  .sm\:flex-initial {
    flex: 0 auto;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:bg-logo-alt {
    background-image: url("logo-alt-bg.cf459a02.png");
  }

  .sm\:pt-0 {
    padding-top: 0;
  }

  .sm\:pr-3 {
    padding-right: .75rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

/*# sourceMappingURL=index.abde6251.css.map */
